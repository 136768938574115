import { http } from '@/service/api/http';
import { constants as consts } from '@/libs/constants';

const SOCKET = {
  [consts.lang.EN]: `${consts.config.TTS.SERVER}/en/ws?spk=700`,
  [consts.lang.AR]: `${consts.config.TTS.SERVER}/ar/ws`,
};

const generateURL = (lang, voice = 1) => {
  if (lang === consts.lang.EN) return `${SOCKET[lang]}`;

  return `${SOCKET[lang]}?spk=${voice}`;
};

export const voice = {
  en: [
    { id: 10, label: 'Voice 1' },
    { id: 9, label: 'Voice 2' },
    { id: 8, label: 'Voice 3' },
    { id: 7, label: 'Voice 4' },
    { id: 5, label: 'Voice 5' },
  ],

  ar: [
    { id: 0, label: 'Voice 1' },
    { id: 1, label: 'Voice 2' },
    { id: 2, label: 'Voice 3' },
  ],
};

export class TTS {
  socket;

  #errorListener;

  #streamer;

  getNewSpeech(params) {
    this.startNewConnection(params);
  }

  async getNewSpeechFromRest({ text, lang, voice: spk }) {
    const { data } = await http.post(`/aiu/tts/${lang}`, { text, spk }, {
      responseType: 'blob',
    });

    if (data instanceof Blob) {
      this.#streamer(data);
    }
  }

  async startNewConnection({ text, lang, voice: voiceCategory }) {
    await this.closeConnection();

    this.socket = new WebSocket(generateURL(lang, voiceCategory));

    this.socket.onopen = () => {
      this.socket.send(text);
    };
    this.initializeEvents();
  }

  onError(fn) {
    this.#errorListener = fn;
  }

  streamSpeech(fn) {
    this.#streamer = fn;
  }

  async closeConnection() {
    if (!this.ws) return;
    await this.socket.close();
  }

  initializeEvents() {
    this.socket.addEventListener('error', (event) => {
      if (this.errorListener) this.errorListener(event);
    });

    this.socket.addEventListener('message', (event) => {
      if (event.data instanceof Blob) {
        this.#streamer(event.data);
      }
    });
  }
}
