import { getDocumentClassification } from './getDocumentClassification';
import { getNamedEntityRecognition } from './getNamedEntityRecognition';
import { getReadingComprehension } from './getReadingComprehension';
import { getSentimentAnalysis } from './getSentimentAnalysis';
import { getTextSummarization } from './getTextSummarization';
import { getArabicDiacritization } from './getArabicDiacritization';
import mt from './machineTranslation';
import { getPOS } from './getPOS';

export const nlp = {
  getDocumentClassification,
  getNamedEntityRecognition,
  getReadingComprehension,
  getSentimentAnalysis,
  getTextSummarization,
  getArabicDiacritization,
  mt,
  getPOS,
};
