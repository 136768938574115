import {
  // Pagination,
  Dialog,
  // Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  // InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  // CheckboxButton,
  // CheckboxGroup,
  Switch,
  Select,
  Option,
  // OptionGroup,
  Button,
  // ButtonGroup,
  // Table,
  // TableColumn,
  // DatePicker,
  // TimeSelect,
  // TimePicker,
  // Popover,
  Tooltip,
  // Breadcrumb,
  // BreadcrumbItem,
  Form,
  FormItem,
  Tabs,
  TabPane,
  // Tag,
  Tree,
  // Alert,
  Slider,
  // Icon,
  Row,
  Col,
  Upload,
  Progress,
  // Spinner,
  // Badge,
  // Card,
  // Rate,
  // Steps,
  // Step,
  // Carousel,
  // CarouselItem,
  // Collapse,
  // CollapseItem,
  // Cascader,
  // ColorPicker,
  // Transfer,
  // Container,
  // Header,
  // Aside,
  // Main,
  // Footer,
  // Timeline,
  // TimelineItem,
  // Link,
  // Divider,
  Image,
  // Calendar,
  // Backtop,
  // PageHeader,
  // CascaderPanel,
  Loading,
  // MessageBox,
  Message,
  Notification,
} from 'element-ui';

const elementUIComponents = [
  // Pagination,
  Dialog,
  // Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  // InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  // CheckboxButton,
  // CheckboxGroup,
  Switch,
  Select,
  Option,
  // OptionGroup,
  Button,
  // ButtonGroup,
  // Table,
  // TableColumn,
  // DatePicker,
  // TimeSelect,
  // TimePicker,
  // Popover,
  Tooltip,
  // Breadcrumb,
  // BreadcrumbItem,
  Form,
  FormItem,
  Tabs,
  TabPane,
  // Tag,
  Tree,
  // Alert,
  Slider,
  // Icon,
  Row,
  Col,
  Upload,
  Progress,
  // Spinner,
  // Badge,
  // Card,
  // Rate,
  // Steps,
  // Step,
  // Carousel,
  // CarouselItem,
  // Collapse,
  // CollapseItem,
  // Cascader,
  // ColorPicker,
  // Transfer,
  // Container,
  // Header,
  // Aside,
  // Main,
  // Footer,
  // Timeline,
  // TimelineItem,
  // Link,
  // Divider,
  Image,
  // Calendar,
  // Backtop,
  // PageHeader,
  // CascaderPanel,
  Loading.directive,
  // MessageBox,
  // Message,
  // Notification,
];

export default {
  install: (Vue) => {
    const vue = Vue;

    elementUIComponents.forEach((item) => {
      vue.use(item);
    });

    vue.prototype.$loading = Loading.service;
    vue.prototype.$ELEMENT = { zIndex: 10000 }; // This variable is also defined in variables.scss
    vue.prototype.$notify = Notification;
    vue.prototype.$message = Message;
  },
};
