import videoClassification from '@/service/api/vca/videoClassification';
import videoLandmarkDetection from '@/service/api/vca/videoLandmarkDetection';
import videoEstimation from '@/service/api/vca/videoEstimation';
import videoRecognition from '@/service/api/vca/videoRecognition';
import videoObjectDetection from '@/service/api/vca/videoObjectDetection';
import videoTagging from '@/service/api/vca/videoTagging';
import videoSegmentation from '@/service/api/vca/videoSegmentation';
import videoPoseEstimation from '@/service/api/vca/videoPoseEstimation';
import videoCelebrityDetection from '@/service/api/vca/videoCelebrityDetection';
import videoNotSafeForWork from '@/service/api/vca/videoNotSafeForWork';

export const vca = {
  videoClassification,
  videoEstimation,
  videoRecognition,
  videoObjectDetection,
  videoTagging,
  videoSegmentation,
  videoPoseEstimation,
  videoLandmarkDetection,
  videoCelebrityDetection,
  videoNotSafeForWork,
};
