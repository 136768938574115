export const DOCS = [
  {
    path: 'docs',
    redirect: {
      path: 'docs/intro',
    },
    meta: {
      underDevelopment: true,
    },
  },
  {
    path: 'docs/:docLocation',
    name: 'cms_docs',
    component: () => import('@/views/pages/CMS/Page/Index.vue'),
    meta: {
      navName: 'Documentation',
      navClassName: 'cms_documentation',
      underDevelopment: true,
      keepAlive: true,
      hideFooter: true,
      hideSidebar: true,
      disableLoader: true,
    },
  },
];
