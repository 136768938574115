import Vue from 'vue';
import VueRouter from 'vue-router';
import { Loading } from 'element-ui';
import store from '@/store';
import { isProduction } from '@/libs/constants';
import AIServices from './AIServices';
import Solutions from './Solutions';
import Pricing from './Pricing';
// import Contact from './Contact';
import About from './About';
import Labels from './Labels';
import Login from './Login';
import CMS from './CMS';
import IiaiAuth from '@aipersona/iiai-auth-sdk';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: {
      path: '/home',
    },
  },
  {
    path: '/home',
    name: 'home-page',
    component: () => import('@/views/pages/Home/Index.vue'),
  },
  AIServices,
  ...Login,
  ...Solutions,
  ...Pricing,
  // ...Contact,
  ...About,
  ...Labels,
  CMS,
  {
    path: '*',
    component: () => import('@/views/pages/PageNotFound/Index.vue'),
    meta: {
      hideAppNavigation: true,
      hideFooter: true,
      errorPage: true,
    },
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

let pageLoaderInstance; // page loader instance variable
router.beforeEach(async (to, from, next) => {
  if (to.meta.underDevelopment && isProduction) {
    next({ name: 'home-page' });
    return;
  }
  if (to.meta.disableLoader && from.name === to.name) {
    pageLoaderInstance = null;
    next();
    return;
  }
  // starting page loader
  pageLoaderInstance = Loading.service({
    fullscreen: true,
    lock: true,
    spinner: 'i-rodin-loader', // TODO : Need to change loader icon once the gif is ready from designer end
    background: 'rgba(255, 255, 255, 0.3)',
  });

  if (to.meta.authenticated) {
    if (store.getters.isAuthReady()) {
      if (store.getters.isAuthenticated()) {
        next();
      } else {
        IiaiAuth.login();
        pageLoaderInstance.close();
      }
    } else {
      store.dispatch('syncAuth');
      store.watch(store.getters.isAuthReady, () => {
        if (store.getters.isAuthenticated()) {
          next();
        } else {
          IiaiAuth.login();
          pageLoaderInstance.close();
        }
      });
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  if (pageLoaderInstance) {
    pageLoaderInstance.close(); // closing page loader
  }
});

export default router;
