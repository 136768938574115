import { Notification } from 'element-ui';

export default class ErrorService {
  static onError(error) {
    const { response } = error;
    if (response && response.status > 399 && response.status < 500) {
      ErrorService.logHTTPError(error);
      return;
    }

    ErrorService.logRuntimeError(error);
    // TODO : commenting out global error notification for now
    // ErrorService.displayErrorAlert('Something went wrong. Please try again!!');
  }

  static onWarn(error) {
    ErrorService.logWarningsAndInfo(error);
  }

  static onInfo(error) {
    ErrorService.logWarningsAndInfo(error);
  }

  static initHandler() {
    window.onerror = (message, url, lineNo, columnNo, error) => {
      if (error) {
        ErrorService.onError(error);
        // console.log(message, url, lineNo, columnNo, error); // TODO : handle error with additional details
      }
    };
  }

  static displayInfoAlert(message) {
    Notification.info({ title: 'Info', message });
  }

  static displayErrorAlert(message) {
    Notification.error({ title: 'Error', message });
  }

  static logHTTPError(error) {
    // TODO : Integrate remote log Engine here
    /* eslint-disable no-console */ // TODO : should be removed no-console bypass once after integrating remote logger
    console.log(error);
    /* eslint-enable no-console */ // TODO : should be removed no-console bypass once after integrating remote logger
  }

  static logRuntimeError(error) {
    // TODO : Integrate remote log Engine here
    /* eslint-disable no-console */ // TODO : should be removed no-console bypass once after integrating remote logger
    console.log(error);
    /* eslint-enable no-console */ // TODO : should be removed no-console bypass once after integrating remote logger
  }

  static logWarningsAndInfo(error) {
    // TODO : Integrate remote log Engine here
    /* eslint-disable no-console */ // TODO : should be removed no-console bypass once after integrating remote logger
    console.log(error);
    /* eslint-enable no-console */ // TODO : should be removed no-console bypass once after integrating remote logger
  }
}
