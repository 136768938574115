export default [
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import('@/views/pages/AboutUs/Index.vue'),
    meta: {
      navClassName: 'about',
    },
  },
];
