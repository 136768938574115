import { cms } from './cms';
import { nlp } from './nlp';
import { lg } from './lg';
import { asa } from './asa';
import { aiu } from './aiu';
import { auth } from './auth';
import { vca } from './vca';
import { util } from './util';
import { contact } from './contact/index';

export const api = {
  aiu,
  auth,
  cms,
  nlp,
  vca,
  asa,
  util,
  contact,
  lg,
};
