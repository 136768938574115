export default [
  {
    path: '/labels/:category',
    name: 'service-labels',
    component: () => import('@/views/pages/Labels/Index'),
    meta: {
      navClassName: 'services',
      hideInNav: true,
      hideFooter: true,
      hideSidebar: true,
      hideAppNavigationMenuItems: true,
    },
  },
];
