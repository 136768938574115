import { http } from '@/service/api/http';

export const detectCelebrity = (formData) => http.post('/image/celebrity_detection', formData, {
  headers: {
    'Content-Type': 'multipart/form-data',
    accept: 'application/json',
  },
});

export default {
  detectCelebrity,
};
