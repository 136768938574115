export const qualityEstimation = async (videoUrl) => {
  const videoName = videoUrl.split('/').pop();
  if (videoName) {
    const mockData = await import('@/../public/mocks/videoQualityEstimation.json');
    return mockData[videoName];
  }
  return null;
};

export default {
  qualityEstimation,
};
