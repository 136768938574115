import consts from '@/libs/constants';
import { http } from '@/service/api/http';
import { parseJSON, isObject } from '@/libs/object';

const ASR_CONFIG = consts.config.ASR;

export const languages = {
  en: 'ENGLISH',
  ar: 'ARABIC',
};

const SOCKET = {
  [consts.lang.EN]: `${ASR_CONFIG.SERVER}/asr/en/ws`,
  [consts.lang.AR]: `${ASR_CONFIG.SERVER}/asr/ar/ws`,
};

const generateConfig = ({
  lang,
  aue = ASR_CONFIG.AUE,
  sampleRate = ASR_CONFIG.SAMPLE_RATE,
}) => ({
  audioConfig: {
    aue,
    sampleRate,
  },
  speechConfig: {
    lang: languages[lang],
  },
});

const deconstructReponse = (response) => {
  try {
    const keyString = response.substring(0, response.indexOf(':'));
    const dataString = response.substring(response.indexOf(':') + 1).trim();

    return { [keyString]: JSON.parse(dataString) };
  } catch (ex) {
    return response;
  }
};

const HttpURL = {
  en: '/audio/asr/en',
  ar: '/audio/asr/ar',
};

const ws = new WeakMap();
const config = new WeakMap();
const response = new WeakMap();
const open = new WeakMap();
const close = new WeakMap();
const connection = new WeakMap();

class AutomaticSpeechRecognition {
  constructor({
    cleanResponse,
    onOpen,
    onResponse,
    onConnectionClose,
  } = {}) {
    open.set(this, onOpen);
    response.set(this, onResponse);
    close.set(this, onConnectionClose);
    this.cleanResponse = cleanResponse;
  }

  cleanResponse = false;

  setConfig = ({ lang, aue, sampleRate } = {}) => {
    if (!lang) return;

    connection.set(this, SOCKET[lang]);
    config.set(this, generateConfig({ lang, aue, sampleRate }));
  }

  start = () => {
    const existingSocket = ws.get(this);

    if (existingSocket) return;

    const URL = connection.get(this);
    const socket = new WebSocket(URL);
    socket.onmessage = this.onResponse;
    socket.onopen = this.onOpen;
    socket.onclose = close.get(this);

    ws.set(this, socket);
  }

  end = () => {
    const socket = ws.get(this);

    if (!socket && socket.readyState > 1) return;

    socket.send(ASR_CONFIG.STOP_COMMAND);

    open.set(this, null);
    connection.set(this, null);
    response.set(this, null);
    close.set(this, null);
  }

  sendAudioData = (_, buffer) => {
    const socket = ws.get(this);

    if (!socket) return;

    if (socket.readyState !== 1) return;

    socket.send(buffer);
  }

  interval = null;

  onReady = (callback) => new Promise((resolve, reject) => {
    if (!ws.has(this)) return reject(new Error('No running WS'));

    this.interval = setInterval(() => {
      const socket = ws.get(this);

      if (socket.readyState === 0) return;

      if (socket.readyState > 1) reject(new Error('WS is already on closing state'));

      if (socket.readyState === 1) {
        resolve(this);

        if (callback) callback(this);
      }

      clearInterval(this.interval);
    }, 100);

    return null;
  });

  onOpen = () => {
    const socket = ws.get(this);

    if (!socket) return;

    const asrConfig = config.get(this);

    socket.send(JSON.stringify(asrConfig));

    const onOpen = open.get(this);

    if (!onOpen) return;

    onOpen(this);
  }

  onResponse = (message) => {
    let data = parseJSON(message.data);

    if (!isObject(data)) data = deconstructReponse(data);

    if (!isObject(data)) return null;

    const socket = ws.get(this);

    if (data.CANCELLED) return socket && socket.close();

    const onResponse = response.get(this);

    if (!onResponse) return null;

    if (!this.cleanResponse) return onResponse(data);

    if (!data
      || !data.result
      || !data.result.isFinal
      || !data.result.bestTranscription
      || !data.result.bestTranscription.transcribedText
    ) return null;

    onResponse(data.result.bestTranscription.transcribedText);

    return null;
  }

  get sampleRate() {
    const settings = config.get(this);

    if (!settings || !settings.audioConfig) return null;

    return parseInt(settings.audioConfig.sampleRate, 10);
  }

  static get defaultSampleRate() {
    return parseInt(ASR_CONFIG.SAMPLE_RATE, 10);
  }
}

const postWav = (lang, formData) => {
  if (!HttpURL[lang]) throw new Error('Language not yet supported!');

  return http.post(HttpURL[lang], formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    },
  });
};

export const getSampleList = () => import('@/../public/examples/ASA/ASR/settings.json');

export const httpASR = {
  postWav,
  getSampleList,
};

export default AutomaticSpeechRecognition;
