<template>
  <div class="info">
    <h6 :class="{ info__title: true, highlight }">
      {{ title }}
    </h6>
    <div class="info__content grid">
      <template v-for="(detail, index) in info">
        <info-label
          v-if="!detail.url"
          :key="index"
          v-bind="detail"
          class="info__content__link hvr-grow-shadow"
        />
        <a
          v-else-if="/^http|^mailto|^maps/.test(detail.url)"
          :key="index"
          :target="/^mailto/.test(detail.url) ? '' : '_blank'"
          :href="detail.url"
          class="info__content__link hvr-grow-shadow"
        ><info-label v-bind="detail" /></a>
        <router-link
          v-else
          :key="index"
          :to="detail.url"
          class="info__content__link hvr-grow-shadow"
          :class="{'disabled': detail.disabled}"
        >
          <info-label v-bind="detail" />
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import InfoLabel from './InfoLabel.vue';

export default {
  components: { InfoLabel },
  props: {
    title: {
      type: String,
      required: true,
    },
    info: {
      type: Array,
      required: true,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
$icon-size: 0.2rem;

.info {
  &__title {
    margin-bottom: 0.26rem;
    text-transform: uppercase;
    line-height: 0.2rem;
    font-size: 0.16rem;
    font-weight: 700;
    color: rgba($text-dark-bg, .6);

    &.highlight {
      color: $color_white;
    }
  }

  &__content {
    gap: 0.12rem;

    &__link {
      @include typography-description;
      color: rgba($text-dark-bg, .8);
      width: fit-content;

      &.disabled {
        opacity: .6;
        pointer-events: none;
      }
    }
  }
}
</style>
