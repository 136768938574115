import NLP from './NLP/index';
import VCA from './VCA/index';
import AIU from './AIU/index';
import ASA from './ASA/index';
import KG from './KG/index';
// import LG from './LG/index';
import MT from './MT/index';

export default {
  path: '/services',
  name: 'services',
  component: () => import('@/views/pages/AIServices/Index.vue'),
  meta: {
    navClassName: 'services',
  },
  children: [
    MT,
    NLP,
    ASA,
    AIU,
    VCA,
    // LG,
    KG,
  ],
};
