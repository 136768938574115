export const tagVideo = async (videoUrl) => {
  const videoName = videoUrl.split('/').pop();
  if (videoName) {
    const mockData = await import('@/../public/mocks/videoTagging.json');
    return mockData[videoName];
  }
  return null;
};

export default {
  tagVideo,
};
