<template>
  <span class="label">
    <img
      v-if="icon"
      :src="icon"
      :alt="title"
      class="label__icon"
    >
    <span class="label__title">{{ title }}</span>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
$icon-size: 0.2rem;

.label {
  &__title {
    @include typography-description;
  }

  &__icon {
    width: $icon-size;
    height: $icon-size;
    margin-right: 0.12rem;
  }
}
</style>
