export default {
  install: (Vue) => {
    const vue = Vue;
    vue.prototype.$methods = {
      isNumeric(input) {
        const RE = /^-{0,1}\d*\.{0,1}\d+$/;

        return (RE.test(input));
      },
      debounce(callback, wait, immediate = false) {
        let timeout = null;

        return function actualDebounce(...args) {
          const callNow = immediate && !timeout;
          const next = () => callback.apply(this, args);

          clearTimeout(timeout);
          timeout = setTimeout(next, wait);

          if (callNow) {
            next();
          }
        };
      },
    };
  },
};
