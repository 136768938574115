import { constants } from '@/libs/constants';
import { http } from '../http';

const langToPath = {
  [constants.lang.EN]: '/enpos',
  [constants.lang.AR]: '/apos',
};

export const getPOS = async ({ text, lang } = {}) => {
  const langPath = langToPath[lang] || langToPath[constants.lang.EN];

  return http.post(`/nlp${langPath}`, { text });
};
