import { http } from '@/service/api/http';
import IiaiAuth from '@aipersona/iiai-auth-sdk';

export const getAuthStatus = async () => {
  try {
    const data = await IiaiAuth.getCurrentUser();
    if (data) return data;
  } catch (error) {
    return false;
  }
  return false;
};

export const loginUser = async (data) => {
  const response = await http.post('/auth-gateway/user/login', data);
  return response;
};

export const logoutUser = async () => {
  return await IiaiAuth.logout();
};

export const auth = {
  getStatus: getAuthStatus,
  loginUser,
  logoutUser,
};
