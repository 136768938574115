import Vue from 'vue';
import Vuex from 'vuex';
import IiaiAuth from '@aipersona/iiai-auth-sdk';
import ErrorService from '@/service/utils/error';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authUser: null,
    authLoaded: false,
    errors: [],
    nlpExamples: {},
    sidebarCollapsed: false,
  },
  getters: {
    isAuthReady: (state) => () => state.authLoaded,
    // TODO: uncomment to see login version
    // isAuthenticated: () => () => true,
    isAuthenticated: (state) => () => !!state.authLoaded && !!state.authUser,
    isSidebarCollapsed: ({ sidebarCollapsed }) => sidebarCollapsed,
  },
  mutations: {
    SET_ERRORS(state, errors) {
      state.errors = errors;
    },
    SET_AUTH_USER(state, authUser) {
      state.authLoaded = true;
      state.authUser = authUser;
    },
    SET_SIDEBAR_COLLAPSED(state, value) {
      state.sidebarCollapsed = value;
    },
  },
  actions: {
    async syncAuth({ commit }) {
      const user = await IiaiAuth.getCurrentUser();
      commit('SET_AUTH_USER',  user || null);
    },
    storeErrors({
      state,
      commit,
    }, error) {
      ErrorService.onError(error); // Calling Error Service

      // Store error to state
      if (error.response) {
        commit('SET_ERRORS', [...state.errors, error.response]);
      }
    },
  },
  modules: {},
});
