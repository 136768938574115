import qualityEstimation from '@/service/api/aiu/qualityEstimation';
import imageTagging from '@/service/api/aiu/imageTagging';
import instanceSegmentation from '@/service/api/aiu/instanceSegmentation';
import imageObjectDetection from '@/service/api/aiu/imageObjectDetection';
import imageClassification from '@/service/api/aiu/imageClassification';
import imageLandmarkDetection from '@/service/api/aiu/imageLandmarkDetection';
import celebrityDetection from '@/service/api/aiu/celebrityDetection';
import imageNotSafeForWork from '@/service/api/aiu/imageNotSafeForWork';
import { cdn } from '@/service/api/http';
import ocr from './opticalCharacterRecognition';

const fetchImage = (url) => cdn.get(url, { responseType: 'blob' });

export const aiu = {
  fetchImage,
  qualityEstimation,
  imageTagging,
  instanceSegmentation,
  imageObjectDetection,
  imageClassification,
  imageLandmarkDetection,
  ocr,
  celebrityDetection,
  imageNotSafeForWork,
};
