import MTIcon from '@/assets/images/icons/nav/MT.svg';

export default {
  path: 'mt',
  name: 'services_mt',
  component: () => import('@/views/pages/AIServices/MT/Index.vue'),
  meta: {
    icon: MTIcon,
    iconCompact: MTIcon,
    navName: 'machine translation',
    intro: 'A powerful computational linguistics tool that translates text from one language to another.',
    authenticated: true,
  },
};
