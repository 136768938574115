import NLPIcon from '@/assets/images/icons/nav/NLP.svg';

export default {
  path: 'nlp',
  name: 'services_nlp',
  component: () => import('@/views/pages/AIServices/NLP/Index.vue'),
  meta: {
    icon: NLPIcon,
    iconCompact: NLPIcon,
    navName: 'natural language processing',
    intro: 'Empowering your advanced NLP based applications with simple API calls.',
    isComingSoon: false,
  },
  children: [
    {
      path: 'document-classification',
      name: 'services_nlp_document',
      component: () => import('@/views/pages/AIServices/NLP/DocumentClassification/Index.vue'),
      meta: {
        navName: 'document classification',
        abbreviation: 'CLS',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'text-summarization',
      name: 'services_nlp_summarization',
      component: () => import('@/views/pages/AIServices/NLP/TextSummarization/Index.vue'),
      meta: {
        navName: 'text summarization',
        abbreviation: 'TSM',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'sentiment-analysis',
      name: 'services_nlp_sentiment',
      component: () => import('@/views/pages/AIServices/NLP/SentimentAnalysis/Index.vue'),
      meta: {
        navName: 'sentiment analysis',
        abbreviation: 'SEM',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'reading-comprehension',
      name: 'services_nlp_reading',
      component: () => import('@/views/pages/AIServices/NLP/ReadingComprehension/Index.vue'),
      meta: {
        navName: 'reading comprehension',
        abbreviation: 'RCH',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'named-entity-recognition',
      component: () => import('@/views/pages/AIServices/NLP/NamedEntityRecognition/Index.vue'),
      meta: {
        keepAlive: false,
        showNav: true,
        showSlideMenu: true,
        navName: 'named entity recognition',
        abbreviation: 'NER',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'pos',
      name: 'services_nlp_pos',
      component: () => import('@/views/pages/AIServices/NLP/POS/Index.vue'),
      meta: {
        navName: 'part of speech tagging',
        abbreviation: 'POS',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'arabic-diacritization',
      name: 'services_nlp_dia',
      component: () => import('@/views/pages/AIServices/NLP/ArabicDiacritization/Index.vue'),
      meta: {
        navName: 'arabic diacritization',
        abbreviation: 'DIA',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'introduction',
      name: 'services_nlp_intro',
      component: () => import('@/views/pages/AIServices/NLP/Introduction/Index.vue'),
      meta: {
        hideSidebar: true,
        navName: 'introduction',
        navClassName: 'introduction',
      },
    },
  ],
};
