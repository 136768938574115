import { http } from '@/service/api/http';

export const estimateQuality = (formData) => http.post('/image/image_quality_estimation_v1', formData, {
  headers: {
    'Content-Type': 'multipart/form-data',
    accept: 'application/json',
  },
});

export default {
  estimateQuality,
};
