import { http, parser } from '@/service/api/http';

export const postTranslate = (from, to, textArray) => http.post('/translation', {
  from_lang: from,
  to_lang: to,
  text: textArray.filter((text) => text.trim() !== ''),
});

export const postLanguageDetection = (text) => http.post('/detectLangs', { text });

const endpoint = '/translation';
const statusURL = '/task/result/get/v1/';

export const postDocumentParsing = (formData) => parser.post('/doc/v1', formData);
export const documentParsingURL = `${endpoint}${statusURL}`;
export const postDocumentParsingStatus = (taskId) => parser.get(`/task/result/get/v1/${taskId}`);

export default {
  postTranslate,
  postLanguageDetection,
  documentParsingURL,
  postDocumentParsing,
  postDocumentParsingStatus,
};
