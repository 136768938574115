import i18n from '@/i18n/index';

export default [
  {
    path: '/solutions',
    component: () => import('@/views/pages/Solutions/Index'),
    children: [
      {
        path: 'financial',
        component: () => import('@/views/pages/Solutions/FinancialServices/Index'),
        meta: {
          info: i18n.t('solutions.financialServices'),
          bannerImg: '',
        },
      },
      {
        path: 'media',
        component: () => import('@/views/pages/Solutions/MediaAndEntertainment/Index'),
        meta: {
          info: i18n.t('solutions.mediaAndEntertainment'),
          bannerImg: '',
        },
      },
      {
        path: 'education',
        component: () => import('@/views/pages/Solutions/Education/Index'),
        meta: {
          info: i18n.t('solutions.educationService'),
          bannerImg: '',
        },
      },
    ],
  },
];
