import Vue from 'vue';
import VueParticles from 'vue-particles';
import VueObserveVisibility, { ObserveVisibility } from 'vue-observe-visibility';
import IiaiAuth from '@aipersona/iiai-auth-sdk';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faMask,
  faSitemap,
  faPalette,
  faMapMarkerAlt,
  faCalendarDay,
  faUsers,
  faGlobeEurope,
  faCalendarAlt,
  faUserAlt,
  faCity,
  faTh,
  faSortNumericUp,
  faClock,
  faBoxOpen,
  faPercentage,
  faMoneyBillWave,
  faGavel,
  faLanguage,
  faDna,
  faAngleUp,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from './App.vue';
import router from './router';
import ErrorService from './service/utils/error';
import { utils } from './libs/utils';

// Global Variables & Functions
import store from './store';
import { constants } from './libs/constants';
import methods from './libs/methods';
import screen from './libs/screen';
import { api } from './service/api';

// i18n
import i18n from './i18n/index';

import ElementUIComponents from './libs/elementUI';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

library.add(
  faSitemap,
  faPalette,
  faMapMarkerAlt,
  faMask,
  faCalendarDay,
  faUsers,
  faGlobeEurope,
  faCalendarAlt,
  faUserAlt,
  faCity,
  faTh,
  faSortNumericUp,
  faClock,
  faBoxOpen,
  faPercentage,
  faMoneyBillWave,
  faGavel,
  faLanguage,
  faDna,
  faAngleUp,
  faExclamationTriangle,
);

IiaiAuth.initialize().then(() => {

  // Setting Vue configuration setting values
  Vue.config.productionTip = false;
  Vue.config.errorHandler = (error) => ErrorService.onError(error);

  // Handle error outside Vue
  ErrorService.initHandler();

  Vue.prototype.$consts = constants;
  Vue.prototype.$api = api;
  Vue.prototype.$utils = utils;

  Vue.use(VueObserveVisibility);
  Vue.use(ElementUIComponents);
  Vue.use(VueParticles);
  Vue.use(methods);
  Vue.use(screen);
  Vue.directive('observe-visibility', ObserveVisibility);
  Vue.component('font-awesome-icon', FontAwesomeIcon);

  new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
});
