/* eslint-disable object-curly-newline */

const {
  NODE_ENV,
  BASE_URL,
  VUE_APP_BASE_URL,
  VUE_APP_MT_TEXT_LIMIT: TEXT_LIMIT,
  VUE_APP_ASR_DEV_KEY: DEV_KEY,
  VUE_APP_RODIN_SOCKET: RODIN_SOCKET,
  VUE_APP_RODIN_API: RODIN_API,
  VUE_APP_LANGUAGE_CORRECTION: LANGUAGE_CORRECTION,
  VUE_APP_CDN_URL: CDN_URL,
  VUE_APP_CDN_VIDEO_URL: CDN_VIDEO_URL,
  VUE_APP_AI_PARSER_API: AI_PARSER_API,
} = process.env;

const MT_PAIRS = [
  'en-ar',
  'ar-en',
  'en-ru',
  'ru-en',
  'en-de',
  'de-en',
  'en-fr',
  'fr-en',
  'en-fa',
  'fa-en',
  'en-zh',
  'zh-en',
  'en-it',
  'it-en',
  'en-pt',
  'pt-en',
  'ar-ru',
  'ru-ar',
  'ar-fr',
  'fr-ar',
  'en-es',
  'es-en',
  'ar-de',
  'de-ar',
  'ar-es',
  'es-ar',
  'ar-it',
  'it-ar',
  'ar-pt',
  'pt-ar',
  'ar-zh',
  'zh-ar',
];
const MT_LANG_PAIRS = MT_PAIRS.reduce((pairs, pair) => {
  const updatedPairs = pairs;
  const [from, to] = pair.split('-');

  if (!updatedPairs[from]) updatedPairs[from] = [];

  if (updatedPairs[from].indexOf(to) !== -1) return updatedPairs;

  updatedPairs[from].push(to);

  return updatedPairs;
}, {});

export const isDevelopment = NODE_ENV === 'development';
export const isProduction = NODE_ENV === 'production';
const API = isDevelopment ? '/dev' : RODIN_API;
const CMS_SERVER = isDevelopment ? '/cms' : 'https://rodin.g42.ai/cms';
const publicPath = `${window.location.origin}${BASE_URL || VUE_APP_BASE_URL || ''}`;
const lang = {
  LANGUAGE_CORRECTION: !!LANGUAGE_CORRECTION,
  DETECT: 'detect',
  EN: 'en',
  AR: 'ar',
  FA: 'fa',
  ZH: 'zh',
  RU: 'ru',
  FR: 'fr',
  DE: 'de',
  IT: 'it',
  PT: 'pt',
  ES: 'es',
};

const config = {
  ASR: {
    DEV_KEY,
    AUE: 'PCM',
    DEV_ID: 6888,
    SAMPLE_RATE: 16000,
    STOP_COMMAND: 'AudioEnd',
    AVAILABLE_LANG: [lang.EN, lang.AR],
    SERVER: `${RODIN_SOCKET}/asr-ws`,
    ALLOWED_FORMATS: ['audio/wav', 'audio/mp3'],
  },
  TTS: {
    SERVER: `${RODIN_SOCKET}/wsstts/tts`,
  },
  MT: {
    AVAILABLE_LANG: ['detect', 'en', 'ar', 'ru', 'de', 'fa', 'fr', 'zh', 'it', 'pt', 'es'],
    TEXT_LIMIT: !TEXT_LIMIT ? null : parseInt(TEXT_LIMIT, 10),
    LANG_PAIRS: MT_LANG_PAIRS,
    PARSER_API: AI_PARSER_API,
  },
  OCR: {
    ALLOWED_FORMATS: ['image/jpg', 'image/jpeg', 'image/png'],
    AVAILABLE_LANG: [lang.EN, lang.AR],
  },
  PARSER: {
    AVAILABLE_LANG: [lang.EN, lang.AR],
    LANG_PAIRS: MT_LANG_PAIRS,
  },
  CDN: {
    SERVER: CDN_URL || publicPath,
    VIDEOS_CDN_URL: CDN_VIDEO_URL,
  },
  CMS: {
    SERVER: CMS_SERVER,
  },
};

export const constants = {
  lang,
  letterCaseModes: {
    CASE_SENSITIVE: 'CASE_SENSITIVE',
    CASE_INSENSITIVE: 'CASE_INSENSITIVE',
  },
  aiuObjectClassColor: {
    colorsPool: [
      '#BA41FF',
      '#98CCFC',
      '#FF98C9',
      '#BA2437',
      '#F2DCBA',
      '#82F70B',
      '#FFCC16',
      '#3A71FF',
    ],
    handbag: '#BA2437',
    heavy_vehicle: '#F2DCBA',
    person: '#82F70B',
    animal: '#FFCC16',
    boat: '#3A71FF',
  },
  nerEntityConfig: {
    organization: { label: 'Organization', color: '#3A71FF', tag: 'ORG', icon: 'sitemap' },
    work_of_art: { label: 'Work of art', color: '#FC9C0D', tag: 'WORK_OF_ART', icon: 'palette' },
    location: { label: 'Location', color: '#31A83E', tag: 'LOC', icon: 'map-marker-alt' },
    cardinal: { label: 'Cardinal', color: '#B67918', tag: 'CARDINAL', icon: 'mask' },
    event: { label: 'Event', color: '#56CCDB', tag: 'EVENT', icon: 'calendar-day' },
    nationality: { label: 'Nationalities or religious or political groups', color: '#9734E8', tag: 'NORP', icon: 'users' },
    geopolitical: { label: 'Geopolitical', color: '#0C40C6', tag: 'GPE', icon: 'globe-europe' },
    date: { label: 'Date', color: '#D39FFF', tag: 'DATE', icon: 'calendar-alt' },
    person: { label: 'Person', color: '#E00824', tag: 'PERSON', icon: 'user-alt' },
    facility: { label: 'Facility', color: '#98CCFC', tag: 'FAC', icon: 'city' },
    quantity: { label: 'Quantity', color: '#FFCC16', tag: 'QUANTIT', icon: 'th' },
    ordinal: { label: 'Ordinal', color: '#F934F1', tag: 'ORDINAL', icon: 'sort-numeric-up' },
    time: { label: 'Time', color: '#521FD1', tag: 'TIME', icon: 'clock' },
    product: { label: 'Product', color: '#6AF067', tag: 'PRODUCT', icon: 'box-open' },
    percent: { label: 'Percent', color: '#FF98C9', tag: 'PERCENT', icon: 'percentage' },
    money: { label: 'Money', color: '#06764E', tag: 'MONEY', icon: 'money-bill-wave' },
    law: { label: 'Law', color: '#0817A1', tag: 'LAW', icon: 'gavel' },
    language: { label: 'Language', color: '#DDFC62', tag: 'LANGUAGE', icon: 'language' },
    miscellaneous: { label: 'Miscellaneous', color: '#A76707', tag: 'MISC', icon: 'dna' },
  },
  posEntityConfig: {
    NN: { label: 'Noun', color: '#fc9c0d', tag: 'NN' },
    PR: { label: 'Pronoun', color: '#e00824', tag: 'PR' },
    MS: { label: 'Miscellaneous', color: '#31a83e', tag: 'MS' },
    AD: { label: 'Adverb', color: '#3a71ff', tag: 'AD' },
    UH: { label: 'Interjection', color: '#ad5bf2', tag: 'UH' },
    VB: { label: 'Verb', color: '#06764E', tag: 'VB' },
    CC: { label: 'Coordinating conjunction', color: '#521fd1', tag: 'CC' },
    SS: { label: 'Foreign word', color: '#98ccfc', tag: 'SS' },
    DT: { label: 'Determiner', color: '#0817a2', tag: 'DT' },
    IN: { label: 'Preposition or subordinating conjunction', color: '#ff98c9', tag: 'IN' },
    JJ: { label: 'Adjective', color: '#b67918', tag: 'JJ' },
    P: { label: 'Punctuation', color: '#ffcc16', tag: 'P' },
  },
  contact: {
    email: 'contact.iiai@g42.ai',
    mailto: 'mailto:contact.iiai@g42.ai?subject=Rodin Inquiry',
    address: '1st Floor, 1B Building, Mohamed bin Zayed University of Artificial Intelligence, Masdar City Abu Dhabi, United Arab Emirates',
  },
  nerEntitiesDialogPerColumn: 10,
  isDevelopment,
  isProduction,
  API,
  RODIN_SOCKET,
  publicPath,
  config,
};

export default constants;
