import ASAIcon from '@/assets/images/icons/nav/ASA.svg';

export default {
  path: 'asa',
  name: 'services_asa',
  component: () => import('@/views/pages/AIServices/ASA/Index.vue'),
  meta: {
    icon: ASAIcon,
    iconCompact: ASAIcon,
    navName: 'audio and speech analysis',
    intro: 'Enabling your application both can hear and speak, as smart as it should be.',
    isComingSoon: false,
  },
  children: [
    {
      path: 'automatic-speech-recognition',
      name: 'services_asa_automatic-speech-recognition',
      component: () => import('@/views/pages/AIServices/ASA/AutomaticSpeechRecognition/Index.vue'),
      meta: {
        navName: 'automatic speech recognition',
        abbreviation: 'ASR',
        authenticated: true,
        isComingSoon: false,
      },
    },
    // {
    //   path: 'speech-quality-estimation',
    //   name: 'services_asa_speech-quality-estimation',
    //   component: () => import('@/views/pages/AIServices/ASA/SpeechQualityEstimation/Index.vue'),
    //   meta: {
    //     navName: 'speech quality estimation',
    //     abbreviation: 'QUA',
    //     authenticated: true,
    //   },
    // },
    {
      path: 'spoken-language-detection',
      name: 'services_asa_spoken-language-detection',
      component: () => import('@/views/pages/AIServices/ASA/SpokenLanguageDetection/Index.vue'),
      meta: {
        navName: 'spoken language detection',
        abbreviation: 'SLD',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'speech-none-speech-detect',
      name: 'services_asa_speech-none-speech-detect',
      component: () => import('@/views/pages/AIServices/ASA/SpeechNoneSpeechDetect/Index.vue'),
      meta: {
        navName: 'speech non speech detection',
        abbreviation: 'SNSD',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'text-to-speech',
      name: 'services_asa_tts',
      component: () => import('@/views/pages/AIServices/ASA/TextToSpeech/Index.vue'),
      meta: {
        navName: 'text to speech',
        abbreviation: 'TTS',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'introduction',
      name: 'services_asa_intro',
      component: () => import('@/views/pages/AIServices/ASA/Introduction/Index.vue'),
      meta: {
        hideSidebar: true,
        navName: 'introduction',
        navClassName: 'introduction',
      },
    },
  ],
};
