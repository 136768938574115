import { http, cms as cmsServer } from '../http';

const getData = async () => (await http.get('/docs/endpoints')).data;
const getPages = () => cmsServer.get('/pages');
const getPagesDocumentations = () => cmsServer.get('/pages/documentations');

export const cms = {
  docs: {
    getData,
    getPages,
    getPagesDocumentations,
  },
};
