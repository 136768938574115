export const hexToRgb = (hex) => {
  const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);

  if (normal) {
    return normal.slice(1).map((e) => parseInt(e, 16));
  }

  const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);

  if (shorthand) {
    return shorthand.slice(1).map((e) => 0x11 * parseInt(e, 16));
  }

  return hex;
};

export const languageCorrection = (toCorrect) => {
  if (!toCorrect) throw new Error('Language to correct cannot be empty!');

  if (toCorrect === 'zh') return 'ch';

  return toCorrect;
};

export const appendAudioBuffer = (buffers) => {
  const AvailableAudioContext = window.AudioContext || window.webkitAudioContext;
  const audioContext = new AvailableAudioContext();

  let mainBuffer = buffers[0];

  // eslint-disable-next-line no-plusplus
  for (let bfIndex = 1; bfIndex < buffers.length; bfIndex++) {
    const noChannels = Math.min(mainBuffer.numberOfChannels, buffers[bfIndex].numberOfChannels);
    const newBuffer = audioContext.createBuffer(noChannels, (mainBuffer.length + buffers[bfIndex].length), mainBuffer.sampleRate);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < noChannels; i++) {
      const channel = newBuffer.getChannelData(i);
      channel.set(mainBuffer.getChannelData(i), 0);
      channel.set(buffers[bfIndex].getChannelData(i), mainBuffer.length);
    }
    mainBuffer = newBuffer;
  }
  return mainBuffer;
};

const arrayBufferToBase64 = (buffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return btoa(binary);
};

const imageToCanvas = async (imageSrc) => new Promise((resolve) => {
  const img = document.createElement('img');
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  img.onload = ({ target }) => {
    canvas.width = target.naturalWidth;
    canvas.height = target.naturalHeight;

    ctx.drawImage(target, 0, 0);

    resolve(canvas);
  };
  img.crossOrigin = '';
  img.src = imageSrc;
});

// eslint-disable-next-line no-async-promise-executor
const serveImageForDownload = async (imageSrc, imageName = 'image') => new Promise(async (resolve) => {
  const canvas = await imageToCanvas(imageSrc);

  canvas.toBlob((blob) => {
    const a = document.createElement('a');

    a.href = URL.createObjectURL(blob);
    a.target = '_blank';
    a.download = `${imageName}.jpg`;
    a.click();
    resolve();
  }, 'image/jpeg', 0.75);
});

const capitalize = (s) => {
  if (typeof s !== 'string') return '';

  return s.charAt(0).toUpperCase() + s.slice(1);
};

const timeoutMap = new WeakMap();

export const debounceExecution = (id, func, delay = 500) => {
  if (!id) throw new Error('value for ID is required.');

  const timeout = timeoutMap.get(id);

  if (timeout) clearTimeout(timeout);

  timeoutMap.set(id, setTimeout(() => {
    func();
    clearTimeout(timeoutMap.get(id));
    timeoutMap.set(id, null);
  }, delay));
};

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const readAsArrayBufferAsync = (blob) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.onload = () => resolve(reader.result);
  reader.readAsArrayBuffer(blob);
});

/**
 * Format bytes as human-readable text.
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * @return Formatted string.
 */
export function humanFileSize(bytes, si = false, dp = 1) {
  let b = bytes;
  const thresh = si ? 1000 : 1024;

  if (Math.abs(b) < thresh) {
    return `${b} B`;
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    b /= thresh;
    u += 1;
  } while (Math.round(Math.abs(b) * r) / r >= thresh && u < units.length - 1);

  return `${b.toFixed(dp)} ${units[u]}`;
}

// eslint-disable-next-line
export const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
// eslint-disable-next-line
export const isIE = /*@cc_on!@*/false || !!document.documentMode;

export const utils = {
  hexToRgb,
  humanFileSize,
  languageCorrection,
  appendAudioBuffer,
  arrayBufferToBase64,
  serveImageForDownload,
  imageToCanvas,
  capitalize,
  validateEmail,
};
