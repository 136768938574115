import VCAIcon from '@/assets/images/icons/nav/VCA.svg';

export default {
  path: 'vca',
  name: 'services_vca',
  component: () => import('@/views/pages/AIServices/VCA/Index.vue'),
  meta: {
    icon: VCAIcon,
    iconCompact: VCAIcon,
    navName: 'video content analysis',
    intro: 'Unlocking video content analysis capabilities based on cutting-edge deep learning models.',
    isComingSoon: false,
  },
  children: [
    {
      path: 'video-classification',
      name: 'services_vca_video-classification',
      component: () => import('@/views/pages/AIServices/VCA/VideoClassification/Index.vue'),
      meta: {
        navName: 'video classification',
        abbreviation: 'CLS',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'video-quality-estimation',
      name: 'services_vca_video-quality-estimation',
      component: () => import('@/views/pages/AIServices/VCA/VideoQualityEstimation/Index.vue'),
      meta: {
        navName: 'video quality estimation',
        abbreviation: 'QUA',
        authenticated: true,
        isComingSoon: false,
      },
    },
    // for now the service is not ready
    // {
    //   path: 'video-action-recognition',
    //   name: 'services_vca_video-action-recognition',
    //   component: () => import('@/views/pages/AIServices/VCA/VideoActionRecognition/Index.vue'),
    //   meta: {
    //     navName: 'video action recognition',
    //     abbreviation: 'ACT',
    //   },
    // },
    {
      path: 'video-object-detection',
      name: 'services_vca_video-object-detection',
      component: () => import('@/views/pages/AIServices/VCA/VideoObjectDetection/Index.vue'),
      meta: {
        navName: 'video object detection',
        abbreviation: 'OBJ',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'video-tagging',
      name: 'services_vca_video-tagging',
      component: () => import('@/views/pages/AIServices/VCA/VideoTagging/Index.vue'),
      meta: {
        navName: 'video tagging',
        abbreviation: 'TAG',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'video-segmentation',
      name: 'services_vca_video-segmentation',
      component: () => import('@/views/pages/AIServices/VCA/VideoSegmentation/Index.vue'),
      meta: {
        navName: 'video instance segmentation',
        abbreviation: 'SEG',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'video-pose-estimation',
      name: 'services_vca_video-pose-estimation',
      component: () => import('@/views/pages/AIServices/VCA/VideoPoseEstimation/Index.vue'),
      meta: {
        navName: 'video pose estimation',
        abbreviation: 'PSE',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'video-landmark-detection',
      name: 'services_vca_video-landmark-detection',
      component: () => import('@/views/pages/AIServices/VCA/VideoLandmarkDetection/Index.vue'),
      meta: {
        navName: 'video landmark detection',
        abbreviation: 'LAD',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'video-celebrity-detection',
      name: 'services_vca_video-celebrity-detection',
      component: () => import('@/views/pages/AIServices/VCA/VideoCelebrityDetection/Index.vue'),
      meta: {
        navName: 'video celebrity detection',
        abbreviation: 'CEB',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'video-not-safe-for-work',
      name: 'services_aiu_video-nsfw',
      component: () => import('@/views/pages/AIServices/VCA/VideoNotSafeForWork/Index.vue'),
      meta: {
        navName: 'video not safe for work',
        abbreviation: 'NSFW',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'introduction',
      name: 'services_vca_intro',
      component: () => import('@/views/pages/AIServices/VCA/Introduction/Index.vue'),
      meta: {
        hideSidebar: true,
        navName: 'introduction',
        navClassName: 'introduction',
      },
    },
  ],
};
