<template>
  <div id="app">
    <app-navigation
      v-if="!$route.meta.hideAppNavigation"
      :class="navClassName"
      :hide-menu-items="$route.meta.hideAppNavigationMenuItems || false"
    />
    <div id="view-container">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
    </div>
    <app-footer
      v-if="!$route.meta.hideFooter"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import AppNavigation from '@/views/components/AppNavigation/Index.vue';
import AppFooter from '@/views/components/AppFooter/Index.vue';

export default {
  components: {
    AppNavigation,
    AppFooter,
  },
  computed: {
    navClassName() {
      return this.$route.matched.map((item) => item.meta.navClassName).filter((item) => item !== undefined).join('-');
    },
  },
  async created() {
    await this.syncAuth();
  },
  methods: {
    ...mapActions(['syncAuth']),
  },
};
</script>
<style lang="scss">
  @import "assets/css/reset";
  @import "assets/css/public";
  @import "assets/css/overwite";
  @import url('https://fonts.googleapis.com/css2?family=Cairo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  /* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
  @import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined');

  body {
    font-family: Montserrat, Helvetica Neue, Helvetica, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-size: 0.16rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $text-primary;
  }

  #view-container {
    min-height: 100%;
    overflow-x: hidden;
  }
</style>
