import { http } from '@/service/api/http';

export const detectImageObjects = (formData) => http.post('/image/image_object_detection_v1', formData, {
  headers: {
    'Content-Type': 'multipart/form-data',
    accept: 'application/json',
  },
});

export default {
  detectImageObjects,
};
