import enLocale from 'element-ui/lib/locale/lang/en';
import { constants } from '@/libs/constants';

export default {
  title: {
    'all-stack ai service platform': 'All in one AI service solutions',
    'empowering intelligent apps with our customizable AI technology': 'Empowering Businesses with Our Customizable AI Technology',
    'Introduction to Rodin': 'Introduction to Rodin',
    'World\'s First All-Stake One-Stop AI Service Platform': 'Perception & Cloud Powered All-Stack AI Services Platform',
    'Our Vision': 'Our Vision',
    'Why choose us?': 'Why choose us?',
    'What Can You Get with Rodin?': 'What Can You Get with Rodin?',
    'AI Cognitive Services': 'AI Cognitive Services',
    'AI Customized Pipeline Builder': 'AI Customized Pipeline Builder',
    'Customized Deployment': 'Customized Deployment',
    Marketplace: 'Marketplace',
    'Our AI Services': 'Our AI Services',
    'AI Vertical Solutions': 'AI Vertical Solutions',
    'Natural Language Processing': 'Natural Language Processing',
    'Video Content Analysis': 'Video Content Analysis',
    'Automatic Image Understanding': 'Automatic Image Understanding',
    'Audio and Speech Analysis': 'Audio and Speech Analysis',
    'Machine Translation': 'Machine Translation',
    'Knowledge Graph': 'Knowledge Graph',
    'Medical Imaging Services': 'Medical Imaging Services',
    'arabic diacritization': 'Arabic Diacritization',
  },
  modelLabels: 'Below is the full list of objects that <strong>{0}</strong> can identify',
  description: {
    serviceCardMT: 'A powerful computational linguistics tool that translates text from one language to another.',
    'G42 Rodin is the provider': 'G42 Rodin is the provider with not only the most comprehensive AI service repository (30+ services), but also the first solution of customized AI training (standard or customized) and deployment (On-Demand & Independent Deployment).',
    'To be the most comprehensive': 'To be the most comprehensive AI Platform-as-a-Service for businesses and developers in the MENA region and cross the world.',
    'Rodin was conceptualized from Abu Dhabi, UAE in 2019': 'Rodin was conceptualized from Abu Dhabi, UAE in 2019, and has grown in size and magnitude over the past 2 years. Currently, we serve not only large corporations, government bodies, and startups in the Middle East, but also expanding fast to Europe, America, and East Asia. Our vision is to make it easier for businesses and individuals to incorporate Artificial Intelligence, and make the concept of cutting-edge technology less aloof for businesses. We firmly believe that high-tech should not be the privilege of tech giants or AI specialists, and genuine technology should bring benefits to the general public.',
    'We are a complete self-sustained AI technology': 'We are a complete self-sustained AI technology innovation company with researchers, engineers and customer-success engineers that guarantee a 360 degree coverage of customer management.',
    'Rodin was conceptualized from': 'Rodin was conceptualized from Abu Dhabi, UAE in 2019, and has grown in size and magnitude over the past 2 years. Currently, we serve not only large corporations, government bodies, and startups in the Middle East, but also expanding fast to Europe, America, and East Asia.',
    'Our vision is to make': 'Our vision is to make it easier for businesses and individuals to incorporate Artificial Intelligence, and make the concept of cutting-edge technology less aloof for businesses. We firmly believe that high-tech should not be the privilege of tech giants or AI specialists, and genuine technology should bring benefits to the general public.',
    'We are the most comprehensive': 'We provide the best customized AI services solutions for business and developers. Reach out to our AI experts for free consultation!',
    'Exchanging amazing contents with a global audience, whatever language they are saying.': 'Exchanging amazing contents with a global audience, whatever language they are saying.',
    'AI Services Covering': 'AI Services Covered',
    'Major AI Domains': 'Major AI Domains',
    'Empowering your advanced NLP based applications with simple API calls.': 'Endowing your machine with the gift of human language: to think, to speak, and to converse.',
    'Unlocking video content analysis capabilities based on cutting-edge deep learning models.': 'Unlocking instant video understanding capabilities with our cutting-edge deep learning models.',
    'Giving the ability of processing massive images into your able hands.': 'Passing the ability of processing massive amounts of images into your able hands.',
    'Enabling your application both can hear and speak, as smart as it should be.': 'Creating applications that hear and learn to understand sounds. Talking about technology with smart ears.',
    'We are the most comprehensive AI PaaS product in the MENA region and the world. Reach out to our AI experts for free consultation!': 'We are the most comprehensive AI PaaS product in the MENA region and the world. Reach out to our AI experts for free consultation!',
    'Want to know how': 'Want to use Rodin services yourself? Contact us to request a demo account!',
    'Transforming myriads of information into structured graphs of knowledge.': 'Turning myriads of information into powerful graph-based visualizations.',
    'Analyzing and solving critical medical problems based on different imaging modalities and image analysis techniques.': 'Analyzing and solving critical medical problems based on different imaging modalities and image analysis techniques.',
    'A powerful computational linguistics tool that translates text from one language to another.': 'A powerful computational linguistics tool that translates text from one language to another.',
    'With our powerful Natural Language Processing(NLP) technologies': 'With our powerful Natural Language Processing(NLP) technologies, you can now understand, interpret and derive a variety of business insights from your unstructured data, through simple API calls. The demand for integrating NLP into business intelligence systems has never been greater in the era of artificial intelligence. The NLP suite of Rodin covers more than 10 core tasks in multiple languages. And, it’s not only easy to implement but also scalable!',
    'Automatically classify articles or any other text data into specific topics': 'Automatically classify articles or any other text data into specific topics. This service will be the first tool in hand to mine your millions of articles in an elegant manner. It currently supports both English and Arabic.',
    'Being one of the most widely used services in natural language processing': 'Being one of the most widely used services in natural language processing, this service automatically identifies named entities within the articles. It is a cornerstone service for understanding and deriving insights from any natural language content. It currently supports English and Arabic.',
    'A powerful tool to detect emotions by analyzing any text content': 'A powerful tool to detect emotions by analyzing any text content. This service is a basic tool to know more about your clients or users. It currently supports both English and Arabic.',
    'An intelligent and automatic way of answering questions by processing a piece of unstructured text': 'An intelligent and automatic way of answering questions by processing a piece of unstructured text. This service can comprehend and extract the span of text which is the correct answer to the user query.',
    'Automatically condense a piece of text to a shorter version': 'Automatically condense a piece of text to a shorter version, reducing the size of the initial text while at the same time preserving key informational elements and the meaning of content. It currently supports both English and Arabic.',
    'One of the most fundamental and foundational services in processing any natural language content': 'One of the most fundamental and foundational services in processing any natural language content. This service can empower you to identify different grammatical elements in a given piece of content. It currently supports both English and Arabic.',
    'The most powerful technology enabling convenient and effective communication between people around the world': 'The most powerful technology, enabling convenient and effective communication between people around the world. With this service, you can expand your business to the global market and have unlimited possibilities! It currently supports bidirectional translation between English and 10+ languages.',
    'The process of restoring the diacritical marks (short vowels) is known as diacritization. Diacritics are very important for readability and understandability of texts. Our Arabic Diacritization service restores all necessary diacritics while maintaining the neat and clean format of writing.': 'The process of restoring the diacritical marks (short vowels) is known as diacritization. Diacritics are very important for readability and understandability of texts. Our Arabic Diacritization service restores all necessary diacritics while maintaining the neat and clean format of writing.',
  },
  placeholder: {
    maxTxtExceeded: 'File exceeds maximum txt size!',
    maxImageExceeded: 'File exceeds maximum image size!',
    'Type something': 'Type something',
    audioMT: 'Please click the microphone to start recording\n Or Upload audio file (File Size Max {0}MB)',
    'Upload image or text file for translation': 'Upload image or text file for translation',
    'Image file size should be less than 5 MB': 'Image file size should be less than 5 MB. Supporting png and jpg.',
    'Text file size should be less than 2 MB': 'Text file size should be less than 2 MB. Supporting txt.',
    'Translate from': 'Translate from',
    'Translate into': 'Translate into',
    'Detect Language': 'Detect Language',
    'Select Language': 'Select Language',
    'Selected audio': 'Selected audio will appear here',
    'Audio preview': 'Audio Preview',
    'select question': 'Select Question',
  },
  button: {
    // Nav
    home: 'home',
    solutions: 'solutions',
    services: 'services',
    pricing: 'pricing',
    documentation: 'documentation',
    'customer support': 'customer support',
    contact: 'contact',
    'about us': 'about us',
    'try demo': 'Try Demo',
    introduction: 'Introduction',
    download: 'Download',
    'sign in': 'sign in',
    'copy to clipboard': 'Copy to clipboard',
    'Copied to clipboard!': 'Copied to clipboard!',
    'Check the full list of landmarks': 'Check the full list of landmarks',
    'Check the full list of objects': 'Check the full list of objects',
    'Check the full list of classifications': 'Check the full list of classifications',
    'Check the full list of celebrities': 'Check the full list of celebrities',
    'Check the full list of categories': 'Check the full list of categories',
    // NLP
    'natural language processing': 'Natural Language Processing',
    'machine translation': 'Machine Translation',
    'arabic diacritization': 'Arabic Diacritization',
    'named entity recognition': 'Named Entity Recognition',
    'sentiment analysis': 'Sentiment Analysis',
    'reading comprehension': 'Reading Comprehension',
    'text summarization': 'Text Summarization',
    'document classification': 'Document Classification',
    'part of speech tagging': 'Part of Speech Tagging',
    // AIU
    'automatic image understanding': 'Automatic Image Understanding',
    'image tagging': 'Image Tagging',
    'image object detection': 'Image Object Detection',
    'image classification': 'Image Classification',
    'image quality estimation': 'Image Quality Estimation',
    'image instance segmentation': 'Image Instance Segmentation',
    'image landmark detection': 'Image Landmark Detection',
    'optical character recognition': 'Optical Character Recognition',
    'image celebrity detection': 'Image Celebrity Detection',
    'image clustering': 'Image Clustering',
    'image not safe for work': 'Image Not Safe For Work',
    // VCA
    'video content analysis': 'Video Content Analysis',
    'video classification': 'Video Classification',
    'video tagging': 'Video Tagging',
    'video quality estimation': 'Video Quality Estimation',
    'video instance segmentation': 'Video Instance Segmentation',
    'video pose estimation': 'Video Pose Estimation',
    'action recognition': 'Action Recognition',
    'video object detection': 'Video Object Detection',
    'video landmark detection': 'Video Landmark Detection',
    'video action recognition': 'Video Action Recognition',
    'video celebrity detection': 'Video Celebrity Detection',
    'video not safe for work': 'Video Not Safe For Work',
    // ASA
    'audio and speech analysis': 'Audio and Speech Analysis',
    'text to speech': 'Text To Speech',
    'automatic speech recognition': 'Automatic Speech Recognition',
    'language identification': 'Language Identification',
    'speech non speech detection': 'Speech Non Speech Detection',
    'spoken language detector': 'Spoken Language Detector',
    'auto speech recognition': 'Auto Speech Recognition',
    'spoken language detection': 'Spoken Language Detection',
    'speech quality estimation': 'Speech Quality Estimation',
    // KG
    'knowledge graph': 'Knowledge Graph',
    // LG
    'language generation': 'Language Generation',
    // MED
    'medical imaging services': 'Medical Imaging Services',
    console: 'console',
    'Check All Services': 'Check All Services',
    // Home
    'request demo': 'Request Demo',
    login: 'Login',
    'sign out': 'sign out',
    'check our services': 'Check our services',
    'Read more': 'Read more',
    // Footer
    'help center': 'help center',
    'Rodin, Group 42': 'Rodin, Group 42',
    'contact us': 'Contact Us',
    'check pricing plans': 'Check Pricing Plans',
    // Solutions
    'More Details': 'More Details',
    run: 'Run',
    pause: 'Pause',
    reset: 'Reset',
    submit: 'Submit',
    cms: {
      documentation: 'Documentation',
    },
  },
  footer: {
    copyright: 'Copyright © 2021 Group 42, Inc.',
    'All rights reserved.': 'All rights reserved.',
    'try rodin': 'Try Rodin',
    'help center': 'Help Center',
    'contact us': 'Contact us',
    'user guide': 'User Guide',
    pricing: 'Pricing',
    'terms of use': 'Terms of Use',
    'privacy policy': 'Privacy Policy',
    'Leave your email to set up a call.': 'Leave your email to set up a call.',
  },
  industries: {
    'Financial Services': 'Financial Services',
    'Oil and Gas': 'Oil and Gas',
    Education: 'Education',
    'Media and Entertainment': 'Media and Entertainment',
    Healthcare: 'Healthcare',
  },
  solutions: {
    problem: 'problem',
    statement: 'statement',
    solution: 'solution',
    'AI services': 'AI services',
    pipeline: 'Pipeline',
    'financial services': 'Financial Services',
    'media and entertainment': 'Media and Entertainment',
    healthcare: 'Healthcare',
    education: 'Education',
    financialServices: {
      title: 'ai in Financial Services',
      subtitle: 'Being one of the traditional industries with large amounts of data and large numbers of personnel, Financial Services awaits the digital transformation from all sides. Artificial intelligence empowers the industry with capabilities of data cleaning and processing, data insights and analytics, record input and maintenance, fraud detection, and risk management.',
      description: 'Rodin’s AI services are currently transforming how large financial organizations perform in the UAE by streamlining the process and improving efficiency. Our Customized AI pipelines also enable businesses to be self-reliant, empowered and come up with their unique solutions.',
      problem: {
        statement: 'Every year, there are thousands of recordings from call centers of large banks, insurance companies, and other financial institutions. Compared with text files, it’s more challenging to process audio files in large amounts without manual effort. Due to the lack of proper data analytics talents, those data are not put to fair use. Financial institutions want to get the following questions answered:',
        description: `<ol>
                        <li>Among all recordings, in how many cases are customers satisfied or happy? In how many issues are customers angry?</li>
                        <li>How many recordings happened in Arabic/English/Chinese/other languages?</li>
                        <li>What is the subject of the call? Is it about credit card, current account, change password, or else? What is the distribution?</li>
                        <li>Which customer is making the call, and which sales agent picked up the call?</li>
                      </ol>`,
      },
      solution: 'A pipeline consisting of a couple of AI services could process the recordings quickly and provide perfect answers. First, the audio files go to the (<strong>Speech Non Speech Detection</strong>) service. At this step, error audio files without human conversations are singled out and discarded as dirty data. Second, the qualified audios will go to (<strong>Spoken Language Detection</strong>) which identifies the client language. Next, the audio will be converted into editable text using (<strong>Automatic Speech Recognition</strong>). At this stage, the generated text is ready for applying various services in Natural Language Processing. The text can be summarized using (<strong>Text Summarization</strong>), translated to different languages (<strong>Machine Translation</strong>), analyze overall sentiment in those text files (<strong>Sentiment Analysis</strong>), identify the main subject that has been discussed (<strong>Named Entity Recognition</strong>) and text theme can be described by categorizing the text into a set of predefined classes (<strong>Document Classification</strong>). Generated results from Natural Language Processing services can be graphically visualized using a powerful search engine (<strong>Knowledge Graph</strong>) and used in traditional data visualization to get meaningful insights to improve customer service.',
    },
    mediaAndEntertainment: {
      title: 'ai in Media & Entertainment',
      subtitle: 'The overflow of posts, comments, likes and dislikes, followings and followers that appear from social media sources not only vividly demonstrate the unlimited potential of big data, but also makes integration of Artificial Intelligence applicable and possible',
      description: 'Since being initially developed for connecting people, social media has evolved into a completely new entity: to inform, to act, and to disrupt. Government, analytics companies, and social media marketers should combine the important insights from artificial intelligence with traditional media understanding.',
      problem: {
        statement: 'With the amount of news online, it is difficult for analytics firms to generate meaningful insights. The news comes in not only text format but also videos channels.',
        description: 'The dataset is too large that traditional data analytics cannot process efficiently. Media and politicians need to understand real-time news sentiment in different regions, popular topics that are most frequently covered, even when these news articles are in other languages. Analysts can also use this effort for news recommendations.',
      },
      solution: 'Considering different types of data, first text data, for example, news article: first (<strong>Machine Translation</strong>) translate the text to the describable language. People could utilize a series of <strong>Natural Language Processing services</strong> such as <strong>Named Entity Recognition, Sentiment Analysis, Machine Translation, Document Classification</strong> to analyze a large amount of data and generate results. Finally, traditional data analysis strategies or (<strong>Knowledge Graph</strong>) could visualize the analytical results. Input Audios go to (<strong>Speech Non Speech Detection</strong>) first to qualify audios with human conversion, then the spoken language in the audio can be identified using (<strong>Spoken Language Detection</strong>). At this stage (<strong>Automatic Speech Recognition</strong>) can convert the audio to editable text. <strong>Natural Language Processing services</strong> can be used to visualize generated results. For visual data, videos: The audio can be extracted and use <strong>Audio and Speech Analysis services </strong> to convert the audio to text and then visualize the output results using <strong>Natural Language Processing services</strong>. The quality of videos can be estimated (<strong>Video Quality Estimation</strong>). The qualified videos could utilize Video Content Analysis services such as labeling objects at the pixel level (<strong>Video Instance Segmentation</strong>, identifying objects with a certain confidence level (<strong>Video Object Detection</strong>), describe the content using keyword labels (<strong>Video Tagging</strong>), and categorize the video topics into a set of predefined classes (<strong>Video Classification</strong>) to get deeper insights about the visual content.',
    },
    healthcareService: {
      title: 'ai in HealthCare',
      subtitle: 'AI technology is transforming how humans are cared for and treated. AI and robotics assist doctors in medical diagnosis, early detection, medical decision making, and treatment. Technology applications and apps with AI integrated also encourage healthier behaviors in individuals and help with the proactive management of a healthy lifestyle. ',
      description: 'Stemming from robust research in deep learning and Medical Imaging Services, Rodin’s medical AI services start from assisting medical professionals in hospitals, to providing lifelong care to individuals from birth to the end of life.',
      problem: {
        statement: 'In the medical industry, the accuracy of medical diagnosis relies heavily on the expertise of trained doctors.',
        description: 'However, medical costs are high for ordinary families, top medical practitioners are in great lack, and fewer doctors are accessible to the public in developing nations. Using AI in developing countries that do not have the resources will diminish outsourcing and improve patient care. AI can allow for not only patient diagnosis but also areas where healthcare is scarce and allow for a good patient experience by resourcing files to find the best treatment for a patient.',
      },
      solution: '<strong>Medical Image Classification</strong> classifies and qualifies the input image according to type and quality. It will identify whether the input image is Retina, Chest X-Ray, Lung CT, or Mammography image. (<strong>Reina Image Analysis</strong>) gives Ophthalmic Diseases Assessment and grade Diabetic Retinopathy, also known as diabetic eye disease from Grade-0 to Grade-4 according to the severity of the condition. (<strong>Chest X-Ray Analysis</strong>) can detect Pneumonia, Fracture, Thorax Disease, and TB. Moreover, Fractures, Nodules, and Multi-diseases can be diagnosed using (<strong>Lung CT Analysis</strong>). Breast Density can be evaluated using (<strong>Mammography Diagnosis</strong>), and it also can predict Breast Cancer.',
    },
    educationService: {
      title: 'ai in Education',
      subtitle: 'AI can serve schools as much as it can be taught at school. AI is reshaping the core foundations of education, teaching and learning. Participation of AI in learning and teaching signifies not only business values, but also deep transformation of teaching methods and learning outcomes.',
      description: 'Machine learning in education may indeed be an opportunity to walk away from a “one-size-fits-all” approach and to develop teaching tools that personalize learning and are tailored to individual needs and capabilities. AI has also allowed students experiencing learning difficulties to overcome identified gaps and receive higher quality care and customization.',
      problem: {
        statement: 'AI  in education is an opportunity for schools to walk away from a “one-size-fits-all” approach and to develop teaching tools that personalize learning and are tailored to individual needs and capabilities.',
        description: 'AI has also allowed students with learning difficulties to overcome identified gaps and receive higher quality care and customization. There is a massive amount of resources available online in various languages and forms such as research articles, audiobooks, lecture recordings, digital books, and educational mages. It can be a challenge to understand and get the best grasp of those resources. It is even more difficult to find resources that match students’ unique learning styles and preferences.  A student that’s used to learning by reading texts wouldn’t find reading textbooks effective. Teaching students who are comfortable listening to audios by showing them graphs and images would not be a good idea, either. AI comes in place by making flexible the format of learning resources for students and schools.',
      },
      solution: 'Considering different types of input data, first text data: the student can convert the text to speech (<strong>Text to Speech</strong>) service and generate an audiobook, or translate the text (<strong>Machine Translation</strong>) to different language to utilize Natural Language Processing services such as <strong>Named Entity Recognition, Sentiment Analysis, Machine Translation, Document Classification</strong>. The student can visualize the generated results graphically using (<strong>Knowledge Graph</strong>) or traditional data visualization strategies. For Audios, first, it goes to (<strong>Speech Non Speech Detection</strong>) to unqualify audios with no human conversation, then the spoken language will be identified (<strong>Spoken Language Detection</strong>). At this stage, the audio can be converted to editable text and use <strong>Natural Language Processing services</strong> to analyze and visualize generated results. Visual data type, images: first, input images will be filtered according to quality (<strong>Image Quality Estimation</strong>). Second, for qualified images with text, an editable version of the text will be created (<strong>Optical Character Recognition</strong>) and then translated into different languages (<strong>Machine translation</strong>). At this stage, the student can process the text using <strong>Natural Language Processing services</strong> then graphically visualize the generated results (<strong>Knowledge Graph</strong>) or analyze it using traditional data visualization.',
    },
  },
  asr: {
    header: 'Automatic Speech Recognition',
    clickMic: 'Click on the microphone to record your voice',
    selectAudio: 'Select an audio file and play the audio',
  },
  sld: {
    header: 'Spoken Language Detection',
  },
  asa: {
    qua: {
      header: 'Speech Quality Estimation',
      subheader: 'ASR refers to “Automatic Speech Recognition”, which converts the input speech to the text it speaks. It is one of the most important and difficult parts of audio processing. It heavily relies on the speaking language, which makes it need much more data to train the system, to understand different expressions, slangs, accents and even non-existing new words. Currently we are supporting both english and arabic.',
    },
  },
  pricing: {
    title: 'Customized Pricing ',
    subtitle: 'Tailored to Businesses and Governments',
    'How Rodin Pricing Works': 'How Rodin Pricing Works',
    'Customized to Your Needs': 'Customized to Your Needs',
    'Pay-as-You-Go': 'Pay-as-You-Go',
    'Save More by Using More': 'Save More by Using More',
    'Rodin offers': 'Rodin offers you a pay-as-you-go approach for pricing for over 30 services.  With Rodin you pay only for the individual services you need, for as long as you use them, and without requiring long-term contracts or complex licensing.  Rodin pricing is similar to how you pay for utilities like water and electricity.  You only pay for the services you consume, and once you stop using them, there are no additional costs or termination fees.',
    'Different pricing stages': 'Rodin doesn\'t lock you up to any fixed pricing models. Our pricing plan is customized completely based on your usage volume, timeline, and resource requirements. All requirements will be communicated up front, so that you know what you will be paying for.',
    'Pay-as-you-go': 'Pay-as-you-go allows you to easily adapt to changing business needs without overcommitting budgets and improving your responsiveness to changes. With a pay-as-you-go model, you can adapt your business depending on need and not on forecasts, reducing the risk of over positioning or missing capacity.',
    'The tiered pricing': 'With our targets on businesses and governments, higher usage means lower average cost. Our pricing plan provides more benefits for long-term users, large corporations, and heavy usage. Contact us to calibrate your customized pricing plan!',
    product: 'Product',
    pricing: 'Pricing',
    choose: 'Choose the plan that works best for you and your team',
    'per month': 'per month',
    contact: 'Contact Us For Pricing',
    popular: 'Popular',
    'Price Calculator': 'Price Calculator',
    yearly: 'Yearly',
    monthly: 'Monthly',
    customizedSolutions: 'For customized <b>{0}</b> solutions, contact us with the form below:',
    contactForm: {
      header: 'Contact <small>Us</small>',
      subheader: 'I’m interested to hear more about <b>Rodin’s</b> pricing',
    },
    upToCharacters: 'Up to {0}<br>Characters',
    upToImages: 'Up to {0}<br>Images',
    upToMinutes: 'Up to {0}<br>Minutes',
    upload: {
      en: 'Upload - English',
      ar: 'Upload - Arabic',
    },
    realtime: {
      en: 'Real Time - English',
      ar: 'Real Time - Arabic',
    },
    packages: {
      free: 'Free',
      basic: 'Basic',
      premium: 'Premium',
      elite: 'Elite',
      custom: {
        title: 'Customized',
        link: 'Contact us',
        quote: 'to get a quote',
        feature: 'Fully Customized model to your data and needs',
      },
    },
  },
  snsd: {
    header: 'Speech Non Speech Detection',
    subheader: 'SNSD refers to the problem of distinguishing speech segments from background noise in an audio stream. This is a fundamental task which finds a wide range of applications in speech technology: speech coding and monitoring, speech enhancement, or speaker and language identification.',
  },
  services: {
    step: 'Step {0}: ',
    services: 'Services',
    welcome: 'welcome',
    comingSoon: 'Coming soon',
    languages: '{0} languages',
    example: 'Example {0}',
    cluster: 'Cluster {0}',
    otherServices: 'Other Services',
    commonDescription: 'All in One <strong>AI Platform-as-a-Service</strong> for Vast Businesses and Developers',
    aiu: {
      description: 'A picture is worth a thousand words. The design of your smart application is incomplete without the Rodin AIU services. AIU or Automatic Image Understanding is a collection of seven artificially intelligent ready-to-use microservices. AIU services use state-of-the-art machine learning algorithms that run on powerful systems built with cutting-edge technology to give you the most accurate results in the least possible time.',
      TAG: 'Associate a diverse set of tags to your image with the Image Tagging (TAG) service of Rodin. These tags can help you in objectively assessing the image and obtaining much deeper insights into it.',
      CLS: 'Get your images classified/labelled with the state-of-the-art Rodin Image Classification (CLS) service. Image Classification helps you in labelling your images based on their content.',
      QUA: 'Evaluate the quality of your images using the Image Quality Estimation (QUA) service of Rodin. Image Quality Estimation gives you the ability to evaluate the quality (good or bad) of your image automatically based upon its resolution, brightness, and sharpness.',
      OBJ: 'Image Object Detection (OBJ) service of Rodin helps you in locating and identifying various objects in your images with a bounding box. Image Object Detection service has been trained to identify a wide range of diverse objects.',
      SEG: 'If the bounding box is not what you need to locate the objects in the image, then use Rodin\'s state-of-the-art Image Instance Segmentation (SEG) service. Image instance Segmentation helps you in identifying various objects at pixel level.',
      OCR: 'Save your typing time by using Rodin’s Optical Character Recognition (OCR) service that can help you in reading your images containing English, English + Arabic. Rodin’s Optical Character Recognition service is also capable of reading your Emirates ID, Passport and debit/credit card numbers.',
      LAD: 'Identify the world wonders along with other major landmarks with the Image Landmark Detection (LAD) Service of Rodin. Image Landmark Detection is built with state-of-the-art deep learned neural networks that can identify 100 major world landmarks, including 24 landmarks from the UAE.',
      CBT: 'The service of Celebrity Detection (CBT) provides the service of detecting celebrities in images. It gives you the power of tracing popular celebrities exposed to the cameras.',
      CST: 'Image CST refers to “Image Clustering”, which provides the ability of processing massive images into your able hands',
      NSFW: 'The service of Image Not Safe For Work (NSFW) detects pornographic, disgusting images to prevent your application from being polluted by nauty or bad users.',
    },
    vca: {
      description: 'Videos are an essential ingredient of multimedia. Analyse your videos with the artificially intelligent Rodin VCA services that give you a better understanding of your videos and help you in making informed decisions. Rodin\'s VCA or Visual Content Analysis services consist of six ready-to-use microservices designed with state-of-the-art machine learning algorithms that run on powerful systems built with cutting-edge technology to give you accurate results at minimum cost and time!',
      CLS: 'Get your videos classified/labelled with the state-of-the-art Rodin Video Classification (CLS) service. Video Classification helps you in labelling your videos based on their content.',
      ACT: 'ACT refers to “Action Recognition”. It is a type of video classification in which the video is classified based on the action happening in the video.',
      QUA: 'Evaluate the quality of your videos using the Video Quality Estimation (QUA) service of Rodin. Video Quality Estimation gives you the ability to evaluate the quality (good or bad) of your video automatically based upon its resolution, brightness, and sharpness.',
      TAG: 'Associate a diverse set of tags to your video with the Video Tagging service TAG of Rodin. These tags can help you in objectively assessing the video and obtaining much deeper insights into it.',
      OBJ: 'Video Object Detection (OBJ) service of Rodin helps you in locating and identifying various objects in your videos with a bounding box. Video Object Detection service has been trained to identify a wide range of diverse objects.',
      SEG: 'If the bounding box is not what you need to locate the objects in the image, then use Rodin\'s state-of-the-art Video Instance Segmentation (SEG) service. Video Instance Segmentation helps you in identifying various objects at pixel level.',
      PSE: 'The service for Video Pose estimation is detects the pose of human body. With the state of the art deep learning based model, one can detect 19 pairs of key points on the human body and 21 key points on the hands and run in real time.',
      LAD: 'Identify the world wonders along with other major landmarks with the Video Landmark Detection (LAD) Service of Rodin. Video Landmark Detection is built with state-of-the-art deep learned neural networks that can identify 100 major world landmarks, including 24 landmarks from the UAE.',
      CBT: 'The service of Video Celebrity Detection (CBT) is the task of detecting celebrities in videos. The module automatically identifies the celebrities in the input video, which extracts important information from the video.',
      NSFW: 'The service of Video Not Safe For Work (NSFW) detects whether the videos contain any illegal or uncomfortable contents. The module automatically classifies the input video into 8 classes, each with a confidence score.',
    },
    asa: {
      description: 'Audio and Speech analysis empower your application with listening and speaking in the human-natural language capabilities to achieve the maximum intelligence it should have! It will improve your product user experience and help to expand your business scope to more international customers. The modules cover all main aspects of audio processing by using the most advanced cutting-edge deep learning architecture. All to give the highest precision and best performance for your applications.',
      ASR: 'Add interactivity to your smart applications using our powerful Automatic Speech Recognition (ASR). The service recognizes speech in audios or microphone recordings and converts it to readable text.',
      TTS: 'Text to speech is one of the most important services in audio and speech analysis. It will allow converting text to speech in a human-like voice. A great tool to make the text more alive!',
      SNSD: 'SNSD or Speech Activity Detection is an integral part of speech processing. The SNSD service detects the presence or absence of human speech in an audio signal. This service can be used in Speech Recognition and Speech Coding.',
      QUA: 'SQA or Speech Quality Estimation service deals with the quality of speech recording. Estimating the perceived quality of an audio signal is critical for many multimedia and audio processing systems.',
      SLD: 'The Spoken Language Detection SLD, determines the natural languages composed inside a speech signal. Spoken language identification is the process by which the language in a spoken utterance is recognized automatically. Spoken language identification is commonly used in speech translation systems, in multilingual speech recognition, and in speaker diarization.',
    },
    documentClassification: {
      header: 'Document classification',
    },
    POS: {
      header: 'Part of Speech Tagging',
      categories: 'Categories',
      headerSubtitle: '*Please hover on the selected text to see the category',
    },
    namedEntityRecognition: {
      header: 'Named Entity Recognition',
      grTitle: 'Tags in model/Categories',
      grDescription: '*Please hover on the selected text to see the category',
    },
    readingComprehension: {
      header: 'Reading Comprehension',
    },
    sentimentAnalysis: {
      header: 'Sentiment Analysis',
      overallSentiment: 'Overall Sentiment Score',
      sentimentDistribution: 'Sentiment Distribution',
    },
    textSummarization: {
      header: 'Text Summarization',
    },
    languageGeneration: {
      header: 'Language Generation',
      exampleTextTitle: 'Example: Select a sample topic',
      responseLength: 'Response Length',
      temperature: 'Temperature',
    },
    arDiacritization: {
      header: 'Arabic Diacritization',
    },
    imageTagging: {
      header: 'Image Tagging',
    },
    imageObjectDetection: {
      header: 'Image Object Detection',
      totalsDescription: 'We have detected <span>{0} objects</span> in this image',
    },
    imageClassification: {
      header: 'Image Classification',
    },
    opticalCharacterRecognition: {
      header: 'Optical Character Recognition',
    },
    machineTranslation: {
      Text: 'Text',
      Audio: 'Audio',
      Image: 'Image',
      Document: 'Document',
      'Detect Language': 'Detect Language',
      documentBased: {
        status: 'Status',
        translate: 'Translate',
        cancel: 'Cancel',
        download: 'Download',
        waiting: 'Waiting for File',
        translation: 'Ready for Translation',
        queued: 'Queued',
        processing: 'Processing',
        preparing: 'Preparing for Download',
        ready: 'Ready for Download',
        supported: '*Documents supported are: docx, pdf, pptx, xlxs, csv and txt files.',
        maxFileSize: 'File size Max {0}MB',
        filename: 'File Name',
        filesize: 'File Size',
        filetype: 'File Type',
      },
    },
    qualityEstimation: {
      header: 'Image Quality Estimation',
      ok: 'Image Quality is fine',
      warning: 'Image Quality is not fine',
    },
    imageInstanceSegmentation: {
      header: 'Image Instance Segmentation',
    },
    imageLandmarkDetection: {
      header: 'Image Landmark Detection',
    },
    videoClassification: {
      header: 'Video Classification',
    },
    videoTagging: {
      header: 'Video Tagging',
    },
    videoQualityEstimation: {
      header: 'Video Quality Estimation',
      qualified: 'Video Qualified:',
      ok: 'Good',
      warning: 'Not Good',
    },
    videoSegmentation: {
      header: 'Video Instance Segmentation',
    },
    videoPoseEstimation: {
      header: 'Video Pose Estimation',
    },
    videoActionRecognition: {
      header: 'Action Recognition',
    },
    videoObjectDetection: {
      header: 'Video Object Detection',
    },
    videoLandmarkDetection: {
      header: 'Video Landmark Detection',
    },
    celebrityDetection: {
      header: 'Celebrity Detection',
      noCelebDetection: 'Sorry, We could not detect any celebrity.',
    },
    imageClustering: {
      header: 'Image Clustering',
    },
    imageNotSafeForWork: {
      header: 'Image Not Safe For Work',
    },
    videoNotSafeForWork: {
      header: 'Video Not Safe For Work',
    },
    videoCelebrityDetection: {
      header: 'Video Celebrity Detection',
    },
    knowledgeGraph: {
      header: 'Knowledge Graph',
      subHeader: 'The G42 Knowledge Graph (KG) is a powerful, graph-based system that uses cutting-edge AI technologies to enable highly intuitive and multi-dimensional search engine and analytics. For any given domain, the KG encodes the critical entities and the relationships between them in a compact and highly extendable manner. Besides, wherever possible, the KG accentuates entities in the documents and provides related information about them from a vast background knowledge base.',
      'Methodology Creation': 'Methodology Creation',
      'Methodology Usage': 'Methodology Usage',
      'Use Cases': 'Use Cases',
      'Contact Us': 'Contact Us',
      'Starting from a set of interrelated documents': 'Starting from a set of interrelated documents from any given domain, such as news articles, product reviews, or social media posts, the KG builds a unified and connected graph of the mentioned entity names, such as persons, products, countries, etcetera and the sentiments between them.',
      'Co-mentioned entity names': 'Co-mentioned entity names (those discussed together in the same sentence) get encoded as having a relationship in the KG. Whether that relationship is positive, negative, or neutral is determined by the sentiment of the sentences, where those names are mentioned together. With this information, the KG can serve as a highly flexible tool to search for specific terms of interest, see their neighborhood in the graph, along with the surrounding sentiments.',
      'A typical search starts': 'A typical search starts from a single keyword that the user is interested in. The KG shows a sub-graph with that node\'s neighborhood, i.e., the other entities have been most often co-mentioned with it and the sentiments between them.',
      'The search journey proceeds': 'The search journey proceeds with the user selecting one of the nodes in the neighborhood that reflects their search intent or interest. Upon selection, the search result revises itself accordingly to focus on the two nodes\' joint neighborhood, and the process continues as the user selects more nodes. At each step, the search also reports the most relevant parts of the source texts according to the graph currently being displayed. In this manner, the tool guides the user to refine their search intent based on the results, narrowing down on a specific aspect of the entity they started with. Unlike a traditional search engine, the KG achieves this in a visual, intuitive, and easy to use manner, eliminating the need to browse multiple documents to explore a given topic or get a high-level summary of its content.',
      'Enterprises can use the G42 KG for': 'Enterprises can use the G42 KG for',
      'Analyzing product review data': 'Analyzing product review data to extract the relative perception of different products/ parts of the same product/ a product with its market competitors.',
      'Mining news data to automatically': 'Mining news data to automatically extract proponents and opponents of a political figure, public personality, or concept, which can also be used to highlight bias in the news.',
      'Making search more manageable': 'Making search more manageable and more intuitive on its data and documents – allowing an abstracted view of the entities and the topics relevant to them.',
      'Want a customized Knowledge Graph solution for your business': 'Want a customized Knowledge Graph solution for your business',
      'Knowledge Graph Gallery': 'Knowledge Graph Gallery',
      'The following are our previous': 'The following are our previous knowledge graph project pictures.  Knowledge Graph shows a sub-graph of the keyword (such as Middle East, Trump, France, for example), with the neighborhood of the keyword, which are the other entities that have been most often co-mentioned with it, and the sentiments between them.',
    },
    medicalImagingServices: {
      header: 'Medical Imaging Services',
      subHeader: 'Medical Image Analysis is the science of solving/analyzing medical problems based on different imaging modalities and digital image analysis techniques. With successful developments of deep convolutional neural networks (CNN), image classification, object detection, semantic segmentation, and image synthesis frameworks are all utilized to analyze medical images for addressing different tasks. In our Rodin platform, we are providing the outstanding AI algorithms for different tasks which are crucial in medical domain, like Retina Image Analysis, Mammography Diagnosis, Chest X-ray Image Analysis and Lung CT Analysis.',
      'Retina Image Analysis': 'Retina Image Analysis',
      'Medical Image Analysis is the': 'Medical Image Analysis is the science of solving/analyzing medical problems based on different imaging modalities and digital image analysis techniques. ',
      'With successful developments of deep convolutional': 'With successful developments of deep convolutional neural networks (CNN), image classification, object detection, semantic segmentation, and image synthesis frameworks, are all investigated to analyze medical images for addressing different tasks. In our Rodin platform, we are providing the outstanding AI algorithms for different tasks which are crucial in medical domain, like Retina Image Analysis, Mammography Diagnosis, Chest X-ray Image Analysis and Lung CT Analysis.',
      'Mammography Diagnosis': 'Mammography Diagnosis',
      'Breast Cancer is the most common': 'Breast Cancer is the most common cancer in women. It is estimated that one out of eight women will be diagnosed with breast cancer in their lifetime. Mammography screening is the most efficient way for breast cancer early stage testing.',
      'In ACR BI-RADS (Breast Imaging Reporting and Data System)': 'In ACR BI-RADS (Breast Imaging Reporting and Data System), the breast composition is first evaluated. The breast density is divided into a- entirely fatty, b- scattered areas of fibro glandular density, c- heterogeneously dense, d - extremely dense. Research also shows that breast density can itself be a risk factor for developing breast cancer.',
      'In Mammography Diagnosis for': 'In Mammography Diagnosis for breast cancer prediction, we follow the Bi-Rads to detect and classify the lesions like calcification and mass, and then analysis the features of the lesion. Finally, we give evaluation of Bi-Rads assessment as likelihood of cancer.',
      'Chest X-ray Image Analysis': 'Chest X-ray Image Analysis',
      'The chest x-ray is the most': 'The chest x-ray is the most commonly performed diagnostic x-ray examination. A chest x-ray produces images of the heart, lungs, airways, blood vessels and the bones of the spine and chest.',
      'Chest X-ray is used to evaluate the lungs': 'Chest X-ray is used to evaluate the lungs, heart and chest wall and may be used to help diagnose shortness of breath, persistent cough, fever, chest pain or injury. It also may be used to help diagnose and monitor treatment for a variety of lung conditions such as pneumonia, emphysema and cancer.•In our platform, we provide various of AI models for TB Detection, Fracture Detection, Pneumonia Detection and Multi Thorax Disease Detection on Chest Xray.',
      'Lung CT Analysis': 'Lung CT Analysis',
      'Lung CT is the most effective non-invasive': 'Lung CT is the most effective non-invasive detection technology for lung diseases and is widely used for lung disease screening and auxiliary diagnosis.',
      'Trying to avoid the time-consuming and': 'Trying to avoid the time-consuming and labor-intensive manual reading of the radiologists, we provide the AI-models to automatically detecting the fracture of bones and other multi lung diseases, like nodule, stripe, artery calcification, lymph node calcification. Furthermore, we could provide diagnosis supporting for the detected lung nodules, which is quite important on lung cancer prediction.',
      'Want customized Medical Imaging Services for your business': 'Want customized Medical Imaging Services for your business',
      retinaSubTitle: 'Diabetic Retinopathy, also known as diabetic eye disease, is a medical condition in which damage occurs to the retina due to diabetes mellitus. It is a leading cause of blindness in the working-age population of the developed world.',
      retinaDescription: 'Due to the ICO Guidelines for Diabetic Eye Care, Diabetic Retinopathy (DR) Grading Level can be classified into: <ul><li>Grade – 0: No apparent retinopathy.</li><li>Grade – 1: Mild – NPDR.</li><li>Grade – 2: Moderate – NPDR.</li><li>Grade – 3: Severe – NPDR.</li><li> Grade – 4: PDR.</li></ul>',
      retinaSubDescription: 'In ophthalmology, fundus screening is an economic and effective way to prevent blindness as early as possible. Blindness can be caused by diabetes, glaucoma, cataract, age-related macular degeneration (AMD), and many other causes. Ophthalmic Disease Assessment can classify multiple ophthalmic diseases such as diabetes, glaucoma, cataract, AMD, hypertension, myopia, and other diseases/abnormalities.',
    },
    inputLanguage: {
      title: 'Choose Input Language',
    },
    carousel: {
      title: 'Select an image to run and see results',
      videoTitle: 'Select a video to run and see the results',
      zipTitle: 'Select a zip to run and see the results',
    },
    upload: {
      dragAndDrop: 'Drag & Drop your image here or',
      browseImage: 'Browse image',
      imageSizeText: 'Supported image formats: jpg and png. Please upload an image file less than',
      imageLargeText: 'For larger image please use the API',
      title: 'Or Upload Your Own Image',
    },
    capitalLettersRadio: {
      title: 'Select how to handle capital letters',
    },
    exampleText: {
      title: 'Select a piece of text to run and see results',
      subtitle: 'Select one of the following examples',
      placeholder: 'The full text will appear here after selection',
    },
    exampleAudio: {
      title: 'Select an audio file to run and see the results',
    },
    ownVoice: 'Or Play your own audio by',
    exampleQuestion: {
      title: 'Select the question you want to ask',
      subtitle: 'Or type your own question ',
      inputPlaceholder: 'Type the question',
    },
    generatedResult: {
      title: 'Generated Results',
      subtitle: 'Results of the {0}',
      placeholder: 'Result will appear here',
    },
    loginPromo: {
      title: {
        text: 'Want to try with your own texts?',
        image: 'Want to try with your own images?',
        audio: 'Want to try with your own audio?',
        video: 'Want to try with your own videos?',
      },
      or: 'or ',
      contactLink: 'Please contact us',
      loginLink: 'Login',
    },
    polarity: {
      positive: 'Positive',
      neutral: 'Neutral',
      negative: 'Negative',
    },
    TTS: {
      header: 'Text To Speech',
      subheader: 'TTS refers to “Text to Speech” service, which given a paragraph of input text, will generate human-like speech audio. The traditional way of achieving this can be created by concatenating pieces of recorded speech that are stored in a database. While with the power of most cutting-edge Artificial intelligence technology, our TTS service can be much more clear and natural than the old method, also providing a variety of human voices and accents.',
    },
  },
  advantage: {
    '20+ ready-to-go AI': '30+ ready-to-go AI microservice APIs that could process tasks independently or be incorporated into smart applications. Rodin presents the highest performing AI microservices that are on or above industry level, and are verified by our capable AI scientists. Our AI services fall into the following 6 domains: Machine Translation, Natural Language Processing, Video Content Analysis, Automatic Image Understanding, Audio and Speech Analysis, Knowledge Graph.',
    'We support the integration': 'We support the integration of multiple AI services or even AI pipelines to process complex tasks. Clients could build up their customized AI pipelines by simply drag-and-drop pre-built and pre-trained AI services. Users can also train their own AI models or fine-tune our existing models by changing parameters. ',
    'Users could fast-deploy their': 'Users could fast-deploy their AI models or pipelines with one-click. Our solutions support on-demand deployment which integrates with the powerful G42 Cloud, and independent deployment, which support the bare-metal, client’s own VMs, or other major Cloud service providers in the market. For customized deployment, please contact our sales team.',
    'Rodin also serve multiple verticals': 'Rodin also serve multiple verticals to provide industrial AI capabilities. Such verticals include Healthcare, Military, Energy and Utilities, Security and Surveillance.',
    'mt-service-description': 'MT refers to the “Machine Translation” service, is a field of computational linguistics that investigates the use of software to translate text from one language to another. While effective, efficient, and empathetic translation requires highly skilled professionals, MT is the way to solve this. The traditional way of MT performs mechanical substitution of words in one language for words in another, but that alone rarely produces a good translation, with the cutting-edge neural networks and big training data.',
  },
  languages: {
    codes: {
      en: 'en',
      es: 'es',
      ar: 'ar',
      ru: 'ru',
      de: 'de',
      fr: 'fr',
      zh: 'zh',
      it: 'it',
      pt: 'pt',
    },
    en: 'English',
    es: 'Spanish',
    ar: 'Arabic',
    ru: 'Russian',
    de: 'German',
    fr: 'French',
    zh: 'Simplified Chinese',
    it: 'Italian',
    fa: 'Persian',
    pt: 'Portuguese',
    tr: 'Turkish',
    x: 'Unknown',
    detect: 'Detect Language',
  },
  error: {
    errorTitle: 'Error',
    loading: 'Loading error! Please check your connection and try again',
    'Something went wrong. Please try again!!': 'Something went wrong. Please try again!!',
    'Oops Sorry! Couldn\'t find anything': 'Oops Sorry! Couldn\'t find anything',
  },
  pageNotFound: {
    title: 'Sorry, Page Not Found',
    subtitle: 'The page is missing or you assembled the link incorrectly',
    link: 'Go Home',
  },
  contactForm: {
    messagePlaceholder: 'Type Your Message',
    success: {
      header: 'Thank You For Contacting Us!',
      subheader: 'We have received your details. Our team will get in touch with you soon.',
    },
    error: {
      header: 'Something Went Wrong!',
      subheader: 'Please check the details and submit again',
    },
    backButton: 'Go Back',
  },
  contactUs: {
    header: 'Contact <small>Us</small>',
    subheader: 'Feel free to contact us in case of any query regarding our products.',
  },
  login: {
    title: 'login',
    welcome: 'Welcome back, Please login to your account',
    email: 'email address',
    password: 'password',
    remember: 'remember me',
    forgetMessage: 'If you forgot your password,\nplease contact our team',
  },
  customerSupport: {
    header: 'Customer <small>Support</small>',
    subheader: 'Our team is happy to answer your questions.<br>Please fill out the form below and we will get in touch soon.',
  },
  inquiryForm: {
    header: 'Your <small>Inquiry</small>',
    subheader: 'Please select the services where you are looking for support',
    topicsHeader: 'What is your inquiry about? (you can choose more than one)',
    messageHeader: 'You can also provide more details about the inquiry by messaging us',
    topics: {
      'pricing-and-billing': 'Pricing and Billing',
      'technology-questions': 'Technology Questions',
      'marketing-campaign': 'Marketing Campaign',
      'request-demo': 'Request Demo',
      'business-partnership': 'Business Partnership',
      others: 'Others',
    },
  },
  fields: {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    phoneNumber: 'Phone Number',
    companyName: 'Company Name',
    jobTitle: 'Job Title',
    country: 'Country',
  },
  validation: {
    required: 'This field is required',
    email: 'Please enter a valid email address',
  },
  letterCaseModes: {
    [constants.letterCaseModes.CASE_SENSITIVE]: 'Case Sensitive',
    [constants.letterCaseModes.CASE_INSENSITIVE]: 'Case Insensitive',
  },
  ...enLocale,
};
