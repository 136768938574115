import { constants } from '@/libs/constants';

export const objectDetection = async (videoUrl) => {
  const videoName = videoUrl.split('/').pop();
  const resultMap = new Map([
    ['bird_moving.mp4', 'bird%20moving%20around.mp4'],
    ['car_splashing.mp4', 'cars%20splashing%20water.mp4'],
    ['lot_of_people_walking_short.mp4', 'lot%20of%20people%20walking%20short.mp4'],
    ['motorcycle_.mp4', 'motorcycle.mp4'],
    ['person_dancing_short.mp4', 'person%20dancing%20short.mp4'],
    ['person_working_on_laptop_short.mp4', 'person%20workin%20on%20laptop%20short.mp4'],
    ['plane_landing_fast_forward_short.mp4', 'plane%20landing%20fast%20forward%20short.mp4'],
  ]);
  if (videoName && resultMap.has(videoName)) {
    return `${constants.config.CDN.VIDEOS_CDN_URL}/result/obj/${resultMap.get(videoName)}`;
  }
  return null;
};

export default {
  objectDetection,
};
