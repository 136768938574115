import { http } from '@/service/api/http';
import { constants } from '@/libs/constants';
import { languageCorrection } from '@/libs/utils';

export const imageCategories = {
  general: {
    url: '',
    id: 'general',
    label: 'General',
  },
};

export const labelMap = {
  bank_name: 'Bank Name',
  card_number: 'Card #',
  issue_date: 'Issue Date',
  expiry_date: 'Expiry Date',
  card_holder: 'Card Holder Name',
  type: 'Type',
  country_code: 'Country Code',
  surname: 'Surname',
  given_name: 'Given Name',
  passport_number: 'Passport #',
  date_of_birth: 'Date of Birth',
  sex: 'Gender',
  date_of_expiry: 'Date of Expiry',
  machine_code: 'Machine Code 1',
  machine_code2: 'Machine Code 2',
  id_number: 'ID #',
  name: 'Full Name',
  name_arabic: 'Name (Arabic)',
  nationality: 'Nationality',
  nationality_arabic: 'Nationality (Arabic)',
  machine_code1: 'Machine Code 1',
  machine_code3: 'Machine Code 3',
};

const postFormData = (url, formData) => http.post(url, formData, {
  headers: {
    'Content-Type': 'multipart/form-data',
    accept: 'application/json',
  },
});

export const postImage = (formData, query) => {
  const params = query && { ...query };
  if (params && params.lang && constants.lang.LANGUAGE_CORRECTION) {
    params.lang = languageCorrection(query.lang);
  }

  const queryString = new URLSearchParams(params);

  return postFormData(`/aiu/easy-ocr?${queryString}`, formData);
};

export const postImageCategory = (category, formData, query) => {
  if (category === imageCategories.general.id) return postImage(formData, query);

  return postFormData(`/ocr/${imageCategories[category].url}?json_output=true`, formData);
};

export const getSamples = () => import('@/../public/examples/AIU/OCR/settings.json');

export default {
  postImage,
  postImageCategory,
  getSamples,
  imageCategories,
  labelMap,
};
