import { http } from '@/service/api/http';

const getContacts = () => http.get('/get_contact_us');

const postInquiry = (formData) => http.post('/contact_us', formData);

export const contact = {
  getContacts,
  postInquiry,
};
