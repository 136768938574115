<template>
  <footer
    id="app-footer"
    class="container"
  >
    <div class="footer-content grid grid-cols-2">
      <info-block
        :title="$t(`button['services']`)"
        :info="services"
      />
      <!-- <info-block
        :title="$t(`footer['help center']`)"
        :info="help"
      /> -->
      <info-block
        :title="$t(`button['Rodin, Group 42']`)"
        :info="contacts"
        highlight
      />
    </div>
    <div class="copyright">
      <img
        :src="require('@/assets/images/icons/brain-g42-rodin.svg')"
        alt="Copyright Brain G42 Rodin"
        class="copyright__icon"
      >
      <span class="copyright__label font-200">{{ $t(`footer['copyright']`) }}</span>
      <span class="copyright__label">{{ $t(`footer['All rights reserved.']`) }}</span>
    </div>
  </footer>
</template>

<script>
import envelope from '@/assets/images/icons/envelope.svg';
import pin from '@/assets/images/icons/pin.svg';
import InfoBlock from './InfoBlock.vue';

export default {
  components: { InfoBlock },
  data() {
    return {
      services: [
        { url: '/services/mt', title: this.$t("title['Machine Translation']") },
        {
          url: '/services/nlp/document-classification',
          title: this.$t("title['Natural Language Processing']"),
        },
        {
          url: '/services/asa/automatic-speech-recognition',
          title: this.$t("title['Audio and Speech Analysis']"),
        },
        {
          url: '/services/aiu/image-tagging',
          title: this.$t("title['Automatic Image Understanding']"),
        },
        {
          url: '/services/vca/video-classification',
          title: this.$t("title['Video Content Analysis']"),
        },
        {
          url: '/services/kg',
          title: this.$t("title['Knowledge Graph']"),
        },
      ],
      help: [
        { url: '/contact-us', title: this.$t("footer['contact us']") },
        { url: 'https://demo-resource.obs.ae-ad-1.g42cloud.com/resource/resource/resource/API_Document_v1.3.0_2020_06.pdf', title: this.$t("footer['user guide']") },
        { url: '/pricing', title: this.$t("footer['pricing']") },
      ],
      contacts: [
        { url: this.$consts.contact.mailto, icon: envelope, title: this.$consts.contact.email },
        {
          url: window.navigator.appVersion.indexOf('Mac') === -1 ? 'https://www.google.com/maps/place/Mohamed+bin+Zayed+University+of+Artificial+Intelligence/@24.4326227,54.6184724,15z/data=!4m5!3m4!1s0x0:0x649441d6f389bc86!8m2!3d24.4326227!4d54.6184724' : 'maps://?q=24.4326227, 54.6184724',
          icon: pin,
          title: this.$consts.contact.address,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#app-footer {
  display: flex;
  flex-direction: column;
  padding-top: 0.3rem;
  padding-bottom: 0.12rem;
  background: $background-footer;
  color: white;
  font-family: 'Montserrat', sans-serif;

  .footer-content {
    gap: 0.6rem 0.12rem;
    padding: 0.3rem 0 .6rem;
    border-bottom: 1px solid rgba($text-dark-bg, .2);

    @include lg {
      @include grid-cols-4;
      gap: 0 0.12rem;
    }

    @include tablet {
      @include grid-cols-2;
    }
  }

  .copyright {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.15rem 0;

    &__icon {
      margin-right: auto;
    }

    &__label {
      margin: 0.16rem 0 0 0.2rem;
    }
  }

  @include mobile {
    .copyright {
      flex-direction: column;

      &__icon {
        margin-right: 0;
      }

      &__label {
        margin: 0.16rem 0 0 0;
      }
    }
  }
}
</style>
