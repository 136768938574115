import { DOCS } from './DOCS';

export default {
  path: '/cms',
  name: 'cms',
  component: () => import('@/views/pages/CMS/Index.vue'),
  meta: {
    hideInNav: true,
    hideFooter: true,
    hideSidebar: true,
    hideAppNavigation: true,
    underDevelopment: true,
  },
  redirect: {
    path: '/cms/docs',
  },
  children: [
    ...DOCS,
  ],
};
