import store from '@/store';

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/Login/Index.vue'),
    meta: {
      navClassName: 'login',
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated()) {
        next({ name: 'services_mt' });
      }
      next();
    },
  },
];
