import axios from 'axios';
import { constants } from '@/libs/constants';
import ErrorService from '@/service/utils/error';

export const headerFormData = {
  'Content-Type': 'multipart/form-data',
  accept: 'application/json',
};

const httpFactory = ({ baseURL }) => {
  const httpInstance = axios.create({
    baseURL,
    timeout: 20000,
  });

  httpInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const { response } = error;

      if (response && response.status > 399 && response.status < 500) {
        ErrorService.logHTTPError(error);
      }

      return Promise.reject(error);
    },
  );

  return httpInstance;
};

const raw = httpFactory({ });
const self = httpFactory({ baseURL: constants.publicPath });
const http = httpFactory({ baseURL: constants.API });
const parser = httpFactory({ baseURL: constants.config.MT.PARSER_API });
const cms = httpFactory({ baseURL: constants.config.CMS.SERVER });
const ocr = httpFactory({ baseURL: constants.config.OCR.SERVER });
const cdn = httpFactory({ baseURL: constants.config.CDN.SERVER });

export {
  raw,
  cms,
  self,
  http,
  ocr,
  cdn,
  parser,
};
