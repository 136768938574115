// Screen size
const WIDTH_SM = 768;
const WIDTH_MD = 1024;
const WIDTH_LG = 1200;

const EXTRA_SMALL = 'xs';
const SMALL = 'sm';
const MEDIUM = 'md';
const LARGE = 'lg';

const currentDevice = (width) => {
  if (width < WIDTH_SM) {
    return EXTRA_SMALL;
  } if (width >= WIDTH_SM && width <= WIDTH_MD) {
    return SMALL;
  } if (width >= WIDTH_MD && width < WIDTH_LG) {
    return MEDIUM;
  }
  return LARGE;
};

export default {
  install: (Vue) => {
    const vue = Vue;

    vue.prototype.$screen = Vue.observable({
      width: window.innerWidth,
      height: window.innerHeight,
      device: currentDevice(window.innerWidth),
    });

    window.addEventListener('resize', () => {
      vue.prototype.$screen.width = window.innerWidth;
      vue.prototype.$screen.height = window.innerHeight;
      vue.prototype.$screen.device = currentDevice(window.innerWidth);
    });
  },
};
