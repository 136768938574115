import { http } from '@/service/api/http';

export const detectLandmarks = (formData) => http.post('/image/image_landmark_detection_v1', formData, {
  headers: {
    'Content-Type': 'multipart/form-data',
    accept: 'application/json',
  },
});

export default {
  detectLandmarks,
};
