export const getReport = async (imageUrl) => {
  const imageName = imageUrl.split('/').pop();
  if (imageName) {
    const mockData = await import('@/../public/mocks/imageNotSafeForWork.json');
    return mockData[imageName];
  }
  return null;
};

export default {
  getReport,
};
