import KGIcon from '@/assets/images/icons/nav/KG.svg';

export default {
  path: 'kg',
  name: 'services_kg',
  component: () => import('@/views/pages/AIServices/KG/Index.vue'),
  meta: {
    icon: KGIcon,
    iconCompact: KGIcon,
    navName: 'knowledge graph',
    intro: 'Transforming myriads of information into structured graphs of knowledge.',
    authenticated: true,
    isComingSoon: false,
  },
};
