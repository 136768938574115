import { http } from '@/service/api/http';

export const postImageTags = (formData) => http.post('/image/tag', formData, {
  headers: {
    'Content-Type': 'multipart/form-data',
    accept: 'application/json',
  },
});

export default {
  postImageTags,
};
