import { http, headerFormData } from '@/service/api/http';

export const getSampleAudios = () => import('@/../public/examples/ASA/SNSD/settings.json');

export const postSpeechNonSpeechDetection = (formData) => http.post('/audio/snsd_v1', formData, { headers: headerFormData });

const snsd = {
  getSampleAudios,
  postSpeechNonSpeechDetection,
};

export { snsd };
