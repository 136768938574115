import { raw, headerFormData } from '@/service/api/http';

export const getSampleAudios = () => import('@/../public/examples/ASA/QUA/settings.json');

export const postSpeechQualityEstimation = (formData) => raw.post('https://rodin.g42.ai/audio/qua', formData, { headers: headerFormData });

const qua = {
  getSampleAudios,
  postSpeechQualityEstimation,
};

export { qua };
