<template>
  <div
    id="app-nav"
    :class="[navbarStatus ? 'opened': 'closed', currentPage]"
  >
    <router-link
      to="/"
      class="logo"
    />
    <template v-if="!hideMenuItems">
      <button
        class="hamburger-menu"
        @click="toggleNavbar($event)"
      >
        <span class="lines" />
      </button>
      <el-menu
        ref="elMenu"
        :mode="navMode"
        :class="navMode"
        router
        :default-active="$route.path"
      >
        <el-menu-item index="/home">
          {{ $t(`button['home']`) }}
        </el-menu-item>

        <el-submenu
          index="/services"
          popper-class="services"
          class="services"
          :popper-append-to-body="false"
        >
          <template slot="title">
            {{ $t(`button['services']`) }}
          </template>
          <template v-for="(mainRoute, mainIndex) in AIServices.children.filter(({ meta: { hideInNav } }) => !hideInNav)">
            <el-submenu
              v-if="mainRoute.children"
              :key="`${mainRoute.meta.navName}${mainIndex}`"
              :index="`/services/${mainRoute.path}`"
              :disabled="mainRoute.meta.isComingSoon"
            >
              <template slot="title">
                <img
                  :src="mainRoute.meta.icon"
                  :alt="mainRoute.meta.navName"
                >
                <div class="introduction">
                  <h4>{{ $t(`button['${mainRoute.meta.navName}']`) }}</h4>
                  <p
                    v-if="mainRoute.meta.isComingSoon"
                    class="introduction__coming-soon"
                  >
                    ({{ $t('services.comingSoon') }})
                  </p>
                </div>
              </template>
              <el-menu-item
                v-for="(subRoute, subIndex) in mainRoute.children.filter(({ meta: { hideInNav } }) => !hideInNav).slice(0, -1)"
                :key="`${subRoute}${subIndex}`"
                :disabled="subRoute.meta.isComingSoon"
                :index="`/services/${mainRoute.path}/${subRoute.path}`"
              >
                {{ $t(`button['${subRoute.meta.navName}']`) }}
              </el-menu-item>
            </el-submenu>
            <el-menu-item
              v-else
              :key="`${mainRoute.meta.navName}${mainIndex}`"
              :index="`/services/${mainRoute.path}`"
              class="el-submenu"
              :disabled="mainRoute.meta.isComingSoon"
            >
              <div class="el-submenu__title">
                <img
                  :src="mainRoute.meta.icon"
                  :alt="mainRoute.meta.navName"
                >
                <div class="introduction">
                  <h4>{{ $t(`button['${mainRoute.meta.navName}']`) }}</h4>
                  <p
                    v-if="mainRoute.meta.isComingSoon"
                    class="introduction__coming-soon"
                  >
                    ({{ $t('services.comingSoon') }})
                  </p>
                </div>
              </div>
            </el-menu-item>
          </template>
        </el-submenu>
        <el-menu-item index="/about-us">
          {{ $t(`button['about us']`) }}
        </el-menu-item>
        <el-menu-item>
          <el-button
            size="small"
            class="login-button"
            @click="handleLoginClick"
          >
            {{ isAuthenticated() ? $t(`button['sign out']`) : $t(`button['login']`) }}
          </el-button>
        </el-menu-item>
      </el-menu>
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import AIServices from '@/router/AIServices/index';
import IiaiAuth from '@aipersona/iiai-auth-sdk'

export default {
  props: {
    hideMenuItems: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      AIServices,
      navbarStatus: false,
      currentPage: '',
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'isAuthReady']),
    navMode() {
      return ['xs', 'sm'].includes(this.$screen.device) ? 'vertical' : 'horizontal';
    },
  },
  watch: {
    $route(to) {
      this.currentPage = to.name;
      this.navbarStatus = false;
    },
  },
  mounted() {
    this.registerOutsideClick();
  },
  methods: {
    ...mapActions(['syncAuth']),
    async handleLoginClick() {
      if (this.isAuthenticated()) {
        await IiaiAuth.logout()
        await this.syncAuth();
        this.$router.push({ path: '/' });
      } else {
        IiaiAuth.login();
      }
    },

    toggleNavbar(ev) {
      ev.stopPropagation();
      this.navbarStatus = !this.navbarStatus;
    },

    registerOutsideClick() {
      document.addEventListener('click', (event) => {
        if (this.$refs.elMenu && !this.$refs.elMenu.$el.contains(event.target)) {
          this.navbarStatus = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  $app-nav_font-color: #FFFFFF;
  $app-nav-services_font-color: #200F5C;

  @mixin active {
    font-weight: 600;
    i {
      font-weight: 600;
    }
  }

  @mixin font-style {
    font-size: 0.18rem;
    font-weight: 400;
    text-transform: capitalize;
  }

  #app-nav {
    position: absolute;
    z-index: $z-index-navigation;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    font-family: 'Montserrat', sans-serif;

    &.home-page {
      background: rgba(1, 37, 89, .5);
      box-shadow: 0px 12px 13px rgba(26, 18, 75, 0.1);
    }

    @include tablet-and-less {
      &.opened {
        .el-menu {
          max-height: 300vh;
          opacity: 1;
          overflow-y: auto;
          transform: translateY(-.2rem);
        }

        .hamburger-menu {
          .lines {
            background-color: transparent;

            &::before,
            &::after {
              width: 100%;
            }

            &::before {
              transform: translateY(250%) rotate(45deg);
            }
            &::after {
                transform: translateY(-250%) rotate(-45deg);
            }
          }
        }

      }
    }

    .hamburger-menu {
      display: none;
      position: relative;
      width: .35rem;
      height: .35rem;
      margin: .4rem .85rem;

      @include tablet-and-less {
        display: inline-flex;
      }

      .lines {
        width: 100%;
        height: .04rem;
        background-color: white;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-30%);
        border-radius: 30px;
        transition: all 0.35s;

        &::after,
        &::before {
          content: '';
          position: absolute;
          right: 0;
          width: 60%;
          height: .04rem;
          border-radius: 30px;
          background-color: white;
          transition: inherit;
        }

        &::before {
          top: -250%;
        }

        &::after {
          bottom: -250%;
        }

      }

      &:hover {
        .lines {
          &::after,
          &::before {
            width: 100%;
          }
        }
      }
    }

    .logo {
      flex: 1;
      padding-bottom: 5%;
      margin-top: 0.1rem;
      margin-left: 0.85rem;
      background: url('~@/assets/images/icons/nav/Logo.svg') left bottom no-repeat;
      background-size: contain;

      @include tablet-and-less {
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
        padding-bottom: 7%;
      }
    }

    ::v-deep {
      .services {
        .introduction {
          flex: 2;
          margin-left: 0.5em;
          max-width: 20em;

          @include tablet-and-less {
            max-width: none;
          }

          h4 {
            line-height: 1.4;
            font-size: 0.16rem;
            font-weight: 600;

            @media(max-width: 640px) {
              // using px to match other list items and override element-ui
              font-size: 15px;
            }
          }

          &__coming-soon {
            font-weight: 600;
            font-size: .12rem;
            line-height: .16rem;
            text-transform: capitalize;
          }
        }

        & > .el-menu {
          padding: 0;

          :last-child.el-menu-item {
            padding-bottom: 0.16rem;
          }

          & > .el-submenu {
            padding: 0.5em 0;

            @include tablet-and-less {
              padding-top: .1rem;
              padding-bottom: .1rem;
            }

            &.el-menu-item {
              height: auto;
              border-radius: 0.08rem;
              opacity: 1;

              & > .el-submenu__title {
                justify-content: flex-start;

                @include tablet-and-less {
                  padding-left: 0;
                }
              }
            }
            &:hover {
              background: #F8F5FF;
            }
            & > .el-submenu__title {
              display: flex;
              align-items: center;
              margin: 1em 0.5em 1em 0;
              font-size: 0.15rem;
              color: #000;
              background: transparent;

              @include lg {
                justify-content: center;
                margin-left: 1em;
              }

              @include tablet-and-less {
                height: auto;
                margin-top: 0;
                margin-bottom: 0;
              }

              img {
                width: 3em;
                height: 3em;

                @include tablet-and-less {
                  width: .3rem;
                  height: .3rem;
                  margin-right: .1rem;
                }
              }
              .el-submenu__icon-arrow {
                color: #000;
                font-weight: 600;
              }
            }
            .el-menu-item {
              border-bottom: 1px solid #D9CAFF;

              &:last-child {
                border-bottom: none;
              }
              &:hover {
                background: #F8F5FF;
              }
            }

            &.is-disabled {
              & > .el-submenu__title {
                opacity: .4;
              }
            }
          }

          .check-all-services {
            font-size: 0.14rem;
            font-weight: 600;
            line-height: 1.5;
            color: #3A71FF;

            &:after {
              content: 'east';
              margin-left: 0.5em;
              vertical-align: -10%;
              font-family: 'Material Icons', sans-serif;
            }
          }
        }
        .el-menu-item {
          color: #000;
        }
        .el-submenu {
          .el-submenu__title {
            color: #000;
          }
        }
      }

      .solutions {
        & > .el-menu {
          & > .el-menu-item {
            margin: 0.5em 0;
            padding: 0 1em;
            font-size: 0.15rem;
            &:hover {
              background: #F8F5FF;
            }
            img {
              margin-right: 0.5em;
            }
          }
        }
        .el-menu-item {
          color: #000;
        }
        .el-submenu {
          .el-submenu__title {
            color: #000;
          }
        }
      }
    }

    .el-menu {
      @include tablet-and-less {
        position: absolute;
        top: 100%;
        margin-top: 0;
        width: 100%;
        opacity: 0;
        max-height: 0;
        transform: translateY(-50%);
        transition: opacity .6s, max-height .3s, transform .3s;
        overflow: hidden;
      }

      .el-menu-item {
        @include tablet-and-less {
          line-height: .8rem;
          display: flex;
          align-items: center;

          img {
            width: .3rem;
            margin: .1rem .1rem .1rem 0;
          }
        }
        @include tablet {
          font-size: 0.16rem;
        }
      }
    }

    .login-button {
      font-size: 0.18rem;
      font-weight: 500;
      line-height: .2rem;
      padding: 0.10rem 0.34rem;
      margin: 0 0.22rem 0 0.14rem;
      border: none;
      color: $app-nav_font-color;
      background: linear-gradient(101.38deg, #6C23F5 7.91%, #453AFF 140.36%);
      box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.1);
      text-transform: capitalize;

      @include tablet {
        margin: 0;
        font-size: .16rem;
      }

      @include mobile {
        margin: 0;
        font-size: .24rem;
      }

      @include tablet-and-less {
        color: $app-nav-services_font-color;
        background: rgba(217, 202, 255, 0.5);
      }
    }

    /deep/ .el-menu--horizontal {
      flex: 5;
      display: flex;
      justify-content: space-between;
      border: none;
      background: none;

      & > .el-menu-item {
        &:not(.is-active) {
          @include animate-hover;
        }
        @include font-style;
        float: none;
        position: relative;
        display: flex;
        align-items: center;
        color: $app-nav_font-color;
        border: none;
        padding: 0 0.16rem;
        height: $menu-height;
        line-height: $menu-height;

        &:focus,
        &:hover {
          background: none;
        }

        &.is-active {
          color: $app-nav_font-color;
          @include active;
          border: none;
          background: none;
        }
      }

      .el-submenu {
        .el-menu--horizontal {
          position: absolute;
        }

        .el-menu--popup {
          border-radius: 0.08rem;
        }

        .el-submenu__title {
          @include font-style;
          padding: 0 0.16rem;
          color: $app-nav_font-color;

          i {
            color: $app-nav_font-color;
          }

          &:hover {
            background: none;
          }
        }

        &.is-active {
          .el-submenu__title {
            @include active;
            border: none;
          }
        }

        &:hover {
          border-radius: 0.08rem;
        }
      }

      & > .el-submenu > .el-submenu__title {
        height: $menu-height;
        line-height: $menu-height;
      }

      .el-icon-arrow-down {
        margin-left: 0;

        &:before {
          content: 'arrow_drop_down';
          font-family: 'Material Icons', sans-serif;
          font-size: 2em;
        }
      }
    }

    /deep/ .el-menu {
      & > .el-menu-item {
        text-transform: capitalize;
      }

      & > .el-submenu > .el-submenu__title {
        text-transform: capitalize;
        @include tablet {
          font-size: 0.16rem;
        }
      }
    }
  }

  #app-nav.services,
  #app-nav.labels,
  #app-nav.cms_documentation,
  #app-nav.contact,
  #app-nav.login {
    position: relative;
    box-shadow: 0 0.12rem 0.13rem rgba(26, 18, 75, 0.03);
    .logo {
      margin: 0;
      background: url('~@/assets/images/icons/nav/LogoV2.svg') 0.20rem no-repeat;
    }

    /deep/ .el-menu--horizontal {
      border: none;
      background: none;

      & > .el-menu-item {
        color: $app-nav-services_font-color;

        &.is-active {
          color: $app-nav-services_font-color;
        }
      }

      .el-submenu {
        .el-submenu__title {
          color: $app-nav-services_font-color;

          i {
            color: $app-nav-services_font-color;
          }
        }
      }
    }

    .hamburger-menu {
      .lines {
        &,
        &::after,
        &::before {
          background-color: $text-primary;
        }
      }
    }

    &.opened .hamburger-menu .lines {
      background-color: transparent;
    }
  }

</style>
