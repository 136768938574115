import { constants } from '@/libs/constants';
import { http } from '../http';

const langToPath = {
  [constants.lang.EN]: '/eng_named_entity',
  [constants.lang.AR]: '/arabic_named_entity_v1',
};

export const modes = constants.letterCaseModes;

export const getNamedEntityRecognition = async ({ text, lang, mode } = {}) => {
  const langPath = langToPath[lang] || langToPath[constants.lang.EN];

  return http.post(`/nlp${langPath}`, {
    case: lang !== constants.lang.EN ? false : mode === modes.CASE_INSENSITIVE,
    text,
  });
};
