import AIUIcon from '@/assets/images/icons/nav/AIU.svg';

export default {
  path: 'aiu',
  name: 'services_aiu',
  component: () => import('@/views/pages/AIServices/AIU/Index.vue'),
  meta: {
    icon: AIUIcon,
    iconCompact: AIUIcon,
    navName: 'automatic image understanding',
    intro: 'Giving the ability of processing massive images into your able hands.',
    isComingSoon: false,
  },
  children: [
    {
      path: 'image-tagging',
      name: 'services_aiu_image-tagging',
      component: () => import('@/views/pages/AIServices/AIU/ImageTagging/Index.vue'),
      meta: {
        navName: 'image tagging',
        abbreviation: 'TAG',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'image-object-detection',
      name: 'services_aiu_image-object-detection',
      component: () => import('@/views/pages/AIServices/AIU/ImageObjectDetection/Index.vue'),
      meta: {
        navName: 'image object detection',
        abbreviation: 'OBJ',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'image-quality-estimation',
      name: 'services_aiu_quality-estimation',
      component: () => import('@/views/pages/AIServices/AIU/QualityEstimation/Index.vue'),
      meta: {
        navName: 'image quality estimation',
        abbreviation: 'QUA',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'image-instance-segmentation',
      name: 'services_aiu_image-instance-segmentation',
      component: () => import('@/views/pages/AIServices/AIU/ImageInstanceSegmentation/Index.vue'),
      meta: {
        navName: 'image instance segmentation',
        abbreviation: 'SEG',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'image-classification',
      name: 'services_aiu_image-classification',
      component: () => import('@/views/pages/AIServices/AIU/ImageClassification/Index.vue'),
      meta: {
        navName: 'image classification',
        abbreviation: 'CLS',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'image-landmark-detection',
      name: 'services_aiu_image-landmark-detection',
      component: () => import('@/views/pages/AIServices/AIU/ImageLandmarkDetection/Index.vue'),
      meta: {
        navName: 'image landmark detection',
        abbreviation: 'LAD',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'optical-character-recognition',
      name: 'services_aiu_optical-character-recognition',
      component: () => import('@/views/pages/AIServices/AIU/OpticalCharacterRecognition/Index.vue'),
      meta: {
        navName: 'optical character recognition',
        abbreviation: 'OCR',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'image-celebrity-detection',
      name: 'services_aiu_celebrity-detection',
      component: () => import('@/views/pages/AIServices/AIU/CelebrityDetection/Index.vue'),
      meta: {
        navName: 'image celebrity detection',
        abbreviation: 'CBT',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'image-clustering',
      name: 'services_aiu_image-clustering',
      component: () => import('@/views/pages/AIServices/AIU/ImageClustering/Index.vue'),
      meta: {
        navName: 'image clustering',
        abbreviation: 'CST',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'image-not-safe-for-work',
      name: 'services_aiu_image-nsfw',
      component: () => import('@/views/pages/AIServices/AIU/ImageNotSafeForWork/Index.vue'),
      meta: {
        navName: 'image not safe for work',
        abbreviation: 'NSFW',
        authenticated: true,
        isComingSoon: false,
      },
    },
    {
      path: 'introduction',
      name: 'services_aiu_intro',
      component: () => import('@/views/pages/AIServices/AIU/Introduction/Index.vue'),
      meta: {
        hideSidebar: true,
        navName: 'introduction',
        navClassName: 'introduction',
      },
    },
  ],
};
