import { TTS, voice } from './textToSpeech';
import { snsd } from './speechNonSpeechDetection';
import { sld } from './spokenLanguageDetection';
import { qua } from './speechQualityEstimation';
import { httpASR } from './automaticSpeechRecognition';

export const asa = {
  asr: httpASR,
  sld,
  qua,
  TTS,
  voice,
  snsd,
};
