import { constants } from '@/libs/constants';

export const segmentVideo = async (videoUrl) => {
  const videoName = videoUrl.split('/').pop();

  if (videoName) {
    return `${constants.config.CDN.VIDEOS_CDN_URL}/result/pse/${videoName}`;
  }
  return null;
};

export default {
  segmentVideo,
};
