import { http, headerFormData } from '@/service/api/http';

export const getSampleAudios = () => import('@/../public/examples/ASA/SLD/settings.json');

export const postSpokenLanguageDetection = (formData) => http.post('/audio/lid_v1', formData, { headers: headerFormData });

const sld = {
  getSampleAudios,
  postSpokenLanguageDetection,
};

export { sld };
